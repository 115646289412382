.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  color: white;
  min-height: 100vh;
  justify-self: center;
}

.MidContainer {
  display: flex;
  /* background-color: red; */
  align-items: center;
  /* max-width: 1080px; */
  width: 90%;
}

.LoggedInView {
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.StatsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 25%;
  /* background-color: tomato; */
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  .StatsContainer {
    width: 100%;
  }
  .CanvasContianer {
    /* display: none !important; */
    margin-right: auto;
    margin-left: auto;
    width: 90%;
    height: 50px !important;
    min-height: 10vh;
    margin-bottom: 20px;
  }

  .MidContainer {
    display: block;
  }
}

.StatGroupTitle {
  border-radius: 8px;
  font-size: 20px;
  padding: 2px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.397);
  min-width: 95%;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.StatComponent {
  width: 110px;
  /* height: 110px; */
  /* background-color: rebeccapurple; */
  /* padding: 10px; */
  /* border: 1px solid white; */
}

.StatComponentLine {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.StatsComponentTitle {
  font-size: 17px;
}

.StatsComponentValue {
  font-weight: bold;
  font-size: 16px;
}

.StatsComponentImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 50px;
  width: 100%;
  margin-top: 10px;
}

.UsersIcon {
  background-image: url("../../../assets/Icons/usersIcon.png");
}

.RegularUserIcon {
  background-image: url("../../../assets/Icons/RegularUserIcon.png");
}

.AnonIcon {
  background-image: url("../../../assets/Icons/anonIcon.png");
}

.CreatedIcon {
  background-image: url("../../../assets/Icons/CreatedIcon.png");
}

.FavIcon {
  background-image: url("../../../assets/Icons/favIcon.png");
}

.LinkedStickerIcon {
  background-image: url("../../../assets/Icons/LinkedStickerIcon.png");
}

.OrdersIcon {
  background-image: url("../../../assets/Icons/OrdersIcon.png");
}

.PublicCreatedIcon {
  background-image: url("../../../assets/Icons/publicCreatedIcon.png");
}

.SalesIcon {
  background-image: url("../../../assets/Icons/SalesIcon.png");
}

.StickerIcon {
  background-image: url("../../../assets/Icons/stickerIcon.png");
}

.ViewIcon {
  background-image: url("../../../assets/Icons/viewIcon.png");
}

.CanvasContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 75%;
  min-height: 100vh;
  justify-content: center;
}

.DownloadButton {
  border-radius: 8px;
  font-size: 15px;
  padding: 2px;
  font-weight: bold;
  background-color: rgba(83, 83, 83, 0.397);
  color: white;
  text-decoration: none;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  min-width: 95%;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.Disabled {
  display: none;
}

.PreviewComponent {
  width: 100%;
  min-height: 500px;
  padding-top: 30px;
  padding-bottom: 30px;
  /* background-color: red; */
}

.AllPostersView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.PostersView {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* background-color: white; */
  margin: 5px;

  border: 1px solid white;
}

.Pagination {
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.PaginationButton {
  width: 60px;
  height: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.PaginationButton:hover {
  background-color: rgba(255, 255, 255, 0.322);
}
