.VariantContainer {
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  --sheet-color: #ffffff19;
  --border-radius: 17px;
  --controls-width: 100%;
  background-color: var(--sheet-color);
  padding: 5px;
  border-radius: 10px;
}

.VariantButton {
  width: 100%;
  min-width: 120px;
  min-height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  flex-direction: column;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
  background-color: var(--sheet-color);
  padding: 5px;
  text-transform: capitalize;
}

.VariantButtonSelected,
.VariantButton:hover {
  background-color: white;
  color: black;
}

.VariantContainerList {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 600px) {
  .VariantContainerList {
    max-width: 95%;
  }
}

.IntroVideoContainer {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  position: relative;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.IntroVideoContainerMain {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #00000050;
}

.IntroVideoContainer video {
  width: 100%;
  height: 100%;
}

.PosterInfoAttributes {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 25px;
  gap: 10px;
  text-align: start;
}

.PosterInfoActionsIncludedContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: var(--sheet-color);
  font-size: 20px;
  border-radius: 17px;
  gap: 10px;
  padding: 20px;
}

.TextureIcon {
  filter: invert(1);
  background-image: url("../../assets/textureIcon.png");
}
.NotNopeIcon {
  filter: invert(1);
  background-image: url("../../assets/notIcon.png");
}
.QRCodeIcon {
  filter: invert(1);
  background-image: url("../../assets/qrIcon.png");
}
.FilePictureIcon {
  filter: invert(1);
  background-image: url("../../assets/filePictureIcon.png");
}

.FrequentListContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--sheet-color);
  font-size: 18px;
  border-radius: 17px;
  padding: 20px;
}

.FrequentListContainer span {
  margin-bottom: 10px;
  margin-left: 20px;
  text-align: left;
}

.CreationSteps {
  width: 100%;
  background-color: var(--sheet-color);
  border-radius: 17px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 5px;
  font-weight: 200;
  font-style: italic;
  transition: 0.5s;
  color: #ffffff6d;
}

.CreateExternalVideoContainer {
  width: var(--controller-width);
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 2px;
  font-style: italic;
  transition: 0.5s;
  justify-content: center;
  align-items: center;
}

.CreateExternalVideo {
  width: 100%;
  background-color: var(--sheet-color);
  border-radius: 17px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 5px;
  font-weight: 200;
  font-style: italic;
  transition: 0.5s;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CreateCanvasVideo {
  width: 100%;
  height: 30px;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
}

.CreateCanvasImage {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/canvaLogo.png");
}

.CreationSteps b {
  color: white;
}

.CreationStepsBold {
  font-weight: bold;
  color: white;
}

.IntroBodyProps {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.18);
  --controller-width: 250px;
  text-align: start;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.IntroBodyProps b {
  font-size: 80px;
  line-height: 80px;
  width: 600px;
  font-weight: 900;
}

.IntroBodyProps span {
  margin-bottom: 5vh;
  width: 600px;
}

@media only screen and (max-width: 600px) {
  .IntroBodyProps b {
    width: 90%;
    font-size: 11vw;
    line-height: 11vw;
  }

  .IntroBodyProps span {
    font-size: 4vw;
    line-height: 4vw;
    width: 90%;
  }
}

.VariantOrientationContainer {
  width: 100%;
  height: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.VariantOrientationButton {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: var(--sheet-color);

  border-radius: 10px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.5s;
}

.VariantOrientationButtonSelected,
.VariantOrientationButton:hover {
  color: #000;
  background-color: white;
}
