.root {
  width: 100vw;
  min-height: 100vh;
  /* background-color: red; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.betaLogologo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/LOGO\ _\ FULL\ _\ WHITE.png");
  height: 200px;
  width: 300px;
  margin-top: 0px;
}

.SelectButton {
  height: 40px;
  border-radius: 10px;
  width: 300px;
  margin-top: 20px;
  background-color: white;
  color: black;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.SelectButton:hover {
  background-color: rgba(255, 255, 255, 0.661);
}

.QRCodeSection {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: white;
  height: 200px;
  width: 200px;
  border-radius: 20px;
  margin-top: 20px;
}

.GenerateUsRoot {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/scanRequest.jpeg");
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 100px;
}

.GenerateUsMain {
  display: flex;
  gap: 40px;
  width: 600px;
  margin-bottom: 100px;
}

.GenerateUsMainSales {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/sunsetQR.png");
  height: 300px;
  width: 40%;
  border-radius: 10px;
}

.GenerateUsMain span {
  text-align: start;
  width: 60%;
}

.GenerateUsMain h2 {
  background-color: white;
  color: #000000;
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .GenerateUsRoot {
    font-size: 50px;
  }
  .GenerateUsMain {
    flex-direction: column;
    width: 90%;
    align-items: center;
  }

  .GenerateUsMainSales {
    height: 200px;
    width: 160px;
  }

  .root h2 {
    width: 80%;
  }
}
