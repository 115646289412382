.root {
  width: 100vw;
  min-height: 100vh;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 17vh;
}

.LoadingViewLogo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 80px;
  height: 80px;
  background-image: url("../assets/logowhite.png");
}

.WelcomeText {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 23px;
}

.MoreInfoText {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.504);
  margin-bottom: 15px;
}

.root input {
  margin-top: 7px;
  width: 250px;
  height: 37px;
  border: 0px solid #ffffff2f;
  border-radius: 10px;
  background-color: #ffffff08;
  color: white;
  padding: 5px;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
}

.ContinueButton {
  margin-top: 10px;
  width: 250px;
  height: 37px;
  border-radius: 10px;
  background-color: #ffffff2f;
  color: white;
  padding: 5px;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 1s;
  font-weight: bold;
}

.ContinueButton:hover {
  background-color: white;
  color: black;
}

.ContinueButtonCreate {
  background-color: white;
  color: black;
  border: 2px solid white;
}

.ContinueButtonCreate:hover {
  background-color: rgba(0, 0, 0, 0.416);
  color: white;
  border: 2px solid white;
}

.ErrorMessage {
  margin-top: 10px;
  font-size: 11px;
}

.ActionMessuage {
  margin-top: 10px;
  font-size: 11px;
  cursor: pointer;
  text-decoration: underline;
}
