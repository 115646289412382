.root {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  background-color: #060606;
  background-color: #191919;
  min-height: 98vh;
  gap: 20px;
  padding-top: 40px;
  --sheet-color: rgba(228, 228, 228, 0.08);
  --radius: 17px;
}

.AssetPreviewSection {
  width: 30%;
  /* height: 800px; */
  /* background-color: #6b1919; */
}

.AssetDetailSection {
  width: 60%;
  height: 800px;
  /* background-color: #170e0e; */
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.AssetPreview {
  width: 100%;
  aspect-ratio: 4/5;
  /* background-color: #9a0000; */
  border-radius: 17px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
}

.AssetPreview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playButton {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  height: 40px;
  width: 40px;
  background-image: url("../assets/playButtton.png");
  cursor: pointer;
  padding: 17px;
  background-color: var(--sheet-color);
  border-radius: var(--radius);
  width: 100%;
  margin-top: 10px;
}

.playButton:hover {
  background-color: #ffffff34;
}

.pausePlayButton {
  background-image: url("../assets/pauseButton.png");
}

.AssetDetail {
  width: 100%;
  background-color: var(--sheet-color);
  border-radius: var(--radius);
  min-height: 100px;
  padding: 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.AssetDetail b {
  font-size: 26px;
}

.AssetDetail span {
  margin-left: 10px;
}

.AssetDetailDownload {
  width: 100%;
  background-color: var(--sheet-color);
  border-radius: var(--radius);
  min-height: 100px;
  padding: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AssetDetailDownloadButton {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 40px;
  width: 150px;
  background-image: url("../assets/applebutton.svg");
  cursor: pointer;
}

.stickerRoot {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: #060606;
  background-color: #191919;
  min-height: 98vh;
  gap: 20px;
  padding-top: 40px;
  backdrop-filter: blur(10px);
  --sheet-color: #e4e4e414;
}

.MainLogo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logowhite.png");
  height: 100px;
  width: 300px;
  margin-bottom: 70px;
}

.StickerButton {
  width: 500px;
  height: 100px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.603);
  background-color: var(--sheet-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 26px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.GalleryAsset {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: rgb(0, 0, 0);
  background-image: url("../assets/logowhite.png");
  height: 40vh;
  width: 40vh;
  border-radius: 330px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.GalleryAsset .MainLogo {
  margin-bottom: 0px;
  backdrop-filter: blur(5px);
  height: 90%;
  width: 90%;
  border-radius: 330px;
  background-size: 30%;
  border: 5px solid rgba(255, 255, 255, 0.445);
  animation-name: heartBeat;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes heartBeat {
  100% {
    height: 95%;
    width: 95%;
  }
  50% {
    height: 85%;
    width: 85%;
  }
  0% {
    height: 95%;
    width: 95%;
  }
}

@media only screen and (max-width: 600px) {
  .root {
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }

  .AssetPreviewSection {
    width: 100%;
  }

  .AssetDetailSection {
    width: 100%;
    height: auto;
  }

  .StickerButton {
    width: 80%;
  }

  .GalleryAsset {
    width: 80%;
  }
}
