.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  background-color: #000000;
  min-height: 100vh;
  --max-width: 1000px;
  margin-bottom: 100px;
}

.eventsRoot {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  color: white;
  background-color: #000000b9;
  padding: 0 5%;
  min-height: 100vh;
  --max-width: 1000px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-size: 100vw;
  backdrop-filter: blur(13px);
}

.GoodExample {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/GoodUploadExample.png");
  height: 300px;
  width: 500px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.BadExample {
  background-image: url("../../assets/BadUploadExample.png");
}

.RelatedExample {
  background-image: url("../../assets/RelatedImagesExamples.png");
}

.root span,
.eventsRoot span {
  width: 500px;
  text-align: start;
  font-weight: 600;
  font-size: 20px;
}

.root h1,
.eventsRoot h1 {
  margin-top: 40px;
}

.MainTitle {
  text-align: left;
  width: 85%;
  font-size: 3em;
  margin-bottom: 0;
}

.Title {
  font-size: 2.4em;
  margin-bottom: 0.5em;
  text-align: left;
}

.eventsRoot p {
  text-align: left;
  font-size: 26px;
  margin: 0 0 2em;
}

.SubTitle {
  width: 75%;
  text-align: left;
  font-size: 26px;
  margin: 0 0 100px;
  padding-right: 10%;
}

.LinkButton {
  margin: 100px 0 0;
  font-size: 2em;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  padding: 5px 50px;
  border-radius: 50px;
}

.Link {
  margin: unset;
}

@media only screen and (max-width: 600px) {
  .root span,
  .eventsRoot span {
    width: 90%;
  }

  .GoodExample {
    width: 80%;
  }

  .Title {
    font-size: 2em;
  }

  .SubTitle {
    font-size: 16px;
    padding-right: 0;
    width: 90%;
    margin-bottom: 40px;
  }

  .eventsRoot p {
    width: 90%;
    font-size: 16px;
  }

  .Link {
    font-size: 24px;
  }
}
