.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  justify-self: center;
  --radius: 13px;
}

.MidContainer {
  display: flex;
  /* background-color: red; */
  align-items: center;
  /* max-width: 1080px; */
  width: 90%;
}

.AccountContentContainer {
  min-height: var(--min-height);
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: rebeccapurple; */
  padding: 5px;
}

.UpdateOrderDetails {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  /* background-color: rebeccapurple; */
  padding: 10px;
  font-weight: bold;
}

.UpdateOrderDetails input {
  color: white;
  border: none;
  width: 80%;
  padding: 10px;
}

.UpdateOrderDetailsList {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #ffffff18;
  padding: 10px;
  flex-wrap: wrap;
  border-radius: 13px;
  justify-content: center;
}

.UpdateOrderStatusButton {
  width: 150px;
  height: 35px;
  display: flex;
  background-color: #ffffff26;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
}

.UpdateOrderStatusButtonSelected {
  background-color: #ffffffff;
  color: black;
}

.UpdateOrderStatusButton:hover {
  background-color: #ffffffff;
  color: black;
}
