.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  justify-self: center;
  --radius: 13px;
}

.AccountContentContainer {
  min-height: var(--min-height);
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px;
}

.CreateDiscountDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 0 10px;
}

.CreateDiscountDetails input {
  border-radius: 13px;
  background-color: #ffffff23;
  width: 50%;
  height: 36px;
  border: none;
  color: white;
  padding-left: 15px;
  font-family: "Nunito";
}

.RadioButtonContainer {
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.RadioButton {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  padding: 5px;
  background-color: rebeccapurple;
  border: solid 2px white;
}

.RadioButtonPin {
  height: 100%;
  width: 100%;
  border-radius: 100px;
  background-color: #ffffff;
}

.CouponList {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.CouponItem,
.CreateCouponButton {
  width: 100%;
  height: 40px;
  background-color: #ffffff16;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  cursor: pointer;
  gap: 10px;
}

.CouponItem input {
  border: none;
  border-radius: 10px;
  height: 70%;
  background-color: rgba(255, 255, 255, 0.108);
  color: white;
  padding-left: 10px;
}

.ShippingMethodListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 10px;
}

.ShippingMethodListItem {
  background-color: #d9d9d90f;
  padding: 10px 25px;
  border-radius: 13px;
  cursor: pointer;
}

.ShippingMethodListItem:hover {
  background-color: #ffffff18;
}

.ShippingMethodListItem p {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 7px;
}

.HeaderSearch {
  margin: auto;
  width: 100%;
  height: 35px;
  background-color: #ffffff0f;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: 2px solid white;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
}

.ActionButton {
  background-color: #ffffff;
  color: #000000;
  width: 100px;
  border-radius: 13px;
  margin: 10px 0 0 auto;
}

@media only screen and (max-width: 600px) {
  .CreateDiscountDetails h1 {
    font-size: 18px;
  }
  .CreateDiscountDetails input {
    width: 85%;
  }
}
