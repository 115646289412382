.Root {
  width: 100%;
  min-height: 900px;
  /* background-color: rebeccapurple; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
}

.Root h1 {
  font-size: 60px;
}

.SubTitle {
  margin-top: 40px;
  margin-bottom: 10px;

  font-size: 30px;
  width: 80%;
  text-align: flex-start;
}

.HeaderImage {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/emptyHall.jpeg");
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  font-style: italic;
  font-weight: 600;
}

.HappyUnbox {
  width: 150px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/HappyUnbox.png");
  background-color: black;
}

.TapStickerGif {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/TAPSTICKER.gif");
  margin-right: auto;
  border-radius: 10px;
}

.Instruction {
  text-align: flex-start;
  width: 80%;
  margin: 20px;
}

.HappyUser {
  width: 150px;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/NewPosterRemoved.png");
  background-color: black;
}

.DownloadApp {
  width: 150px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/DownloadFromAppStoreApple.png");
  background-color: black;
  cursor: pointer;
}

.AndroidButton {
  height: 40px;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  transition: 105;
}

.AndroidButton:hover {
  background-color: white;
  color: black;
}

.AndroidButtonSelected {
  background-color: white;
  color: black;
}

.AndroidButtonStack {
  display: flex;
  gap: 10px;
  width: 80%;
}

.GalleryAsset {
  min-width: 100px;
  height: 100px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  border: 2px solid white;
  transition: 0.1s;
}

.GalleryAsset:hover {
  border: 5px solid white;
}

.PosterList {
  display: flex;
  gap: 10px;
  width: 80%;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  padding: 10px;
}

.SubTitleNote {
  border: 2px solid white;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  width: 80%;
}
