.root {
  width: 100vw;
  min-height: 100vh;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  --radius: 10px;
  --width: 90%;
  gap: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.PrinterMain {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.SupportDeviceInfo {
  width: 90%;
  max-width: 400px;
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  background-color: #ffffff13;
  align-items: center;
  gap: 20px;
  padding: 30px;
}

.SupportDevicePaperImage,
.SupportDeviceImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/CanonImage.png");
  width: 100%;
  height: 300px;
  border-radius: 20px;
  background-color: rebeccapurple;
}

.SupportDevicePaperImage {
  background-image: url("../assets/CanonPaperImage.png");
}

.SupportDeviceButton {
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: var(--radius);
  border: none;
  color: black;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
