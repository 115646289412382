.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  background-color: #e0e0de;
  min-height: 100vh;
  padding-bottom: 20px;
}

.PosterHeaderSection,
.BurnaboyHeaderSection,
.HeaderSection {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/saintRoom.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  position: relative;
}

.BurnaboyHeaderSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/burnaBackdrop.jpg");
}

.PosterHeaderSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/PostcardStack.jpg");
}

.PosterHeaderSection {
  align-items: flex-start;
  padding-left: 10vw;
  justify-content: flex-start;
  padding-top: 30px;
  gap: 0px;
}

.PosterHeaderSectionTitle {
  font-size: 70px;
  font-weight: bolder;
  font-style: italic;
}

.PosterHeaderSectionSub {
  font-size: 20px;
  font-style: italic;
  text-align: start;
}

@media only screen and (max-width: 600px) {
  .PosterHeaderSectionTitle {
    font-size: 55px;
  }

  .PosterHeaderSectionSub {
    font-size: 16px;
  }
}

.PosterHeaderSectionActionsButton {
  width: 120px;
  height: 33px;
  background-color: white;
  border-radius: 10px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.PosterHeaderSectionActionsContainer {
  display: flex;
  gap: 10px;
  margin-top: 35px;
}

.HeaderSectionButton {
  width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  background-color: #0000003f;
  backdrop-filter: blur(1px);
  cursor: pointer;
  transition: 0.5s;
  position: absolute;
  margin-top: 90vh;
}

.HeaderSectionButton:hover {
  backdrop-filter: blur(5px);
  background-color: #0000008f;
}

.SectionVideoContainer {
  width: 100%;
  height: 30vh;
  background-color: antiquewhite;
  position: relative;
}

.SectionVideoContainer video {
  width: 100%;
  height: 100%;
}

.SectionVideoContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #000000e4;
  font-weight: 600;
}

.HeaderSectionLogo {
  width: 150px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/TobennaWesLogo.png");
}

.PosterSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  flex-wrap: wrap;
  gap: 70px;
  margin-bottom: 30px;
  color: black;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
}

@media only screen and (max-width: 1000px) {
  .PosterSection {
    grid-template-columns: 1fr 1fr;
  }
}

.PosterContainer {
  background-color: transparent;
}

.PosterSectionMessage {
  color: #000;
}

.PosterContainerTop {
  width: 100%;
  display: flex;
}

.PosterContainerBottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: black;
  flex-direction: column;
  padding-top: 10px;
  gap: 1px;
}

.PosterContainerPreview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.PosterContainerInfoSize {
  font-size: 15px;
  color: black;
  font-weight: bold;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
}

.PosterContainerInfoSize span {
  font-size: 14px;
  padding-top: 2px;
}

@media only screen and (max-width: 600px) {
  .PosterContainerInfoSize {
    font-size: 14px;
    height: 20px;
  }
}

.PosterContainerAddToCart {
  width: 100%;
  height: 30px;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  border: 2px solid black;
  transition: 0.5s;
  border-radius: 5px;
  font-weight: bold;
}

.PosterContainerAddToCart:hover {
  border-color: white;
  color: #000000;
  background-color: #f9f9f9;
}

@media only screen and (max-width: 600px) {
  .PosterContainerAddToCart {
    font-size: 14px;
    height: 25px;
  }
}

.PosterFrameContainer {
  width: 100%;
  height: 100%;
  /* background-color: #cecbc0; */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.PosterFrame08 {
  --aspect-ratio: 0.8;
}
.PosterFrame075 {
  --aspect-ratio: 0.75;
}
.PosterFrame067 {
  --aspect-ratio: 0.67;
}
.PosterFrame1 {
  --aspect-ratio: 1;
}

.PosterFrame {
  --width: 90%;
  width: var(--width);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 10px #00000074;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* position: absolute; */
  z-index: 3;
  aspect-ratio: var(--aspect-ratio);
  /* background-image: none !important; */
}

.PosterFrameLoading {
  --width: 77%;
  width: var(--width);
  border: 20px solid #000000;
  padding: 20px;
  position: absolute;
  z-index: 3;
  aspect-ratio: var(--aspect-ratio);
}

.PosterFrameSpinnerContainer {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 5;
  border-radius: 25px;
  padding: 5px;
  backdrop-filter: blur(5px);
  background-color: #00000060;
}

.PosterFrameSpinner {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  border: 7px dotted white;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  padding: 5px;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.PosterFrame08 .PosterFrame {
  background-image: url("../../assets/Frame08.png");
}
.PosterFrame075 .PosterFrame {
  background-image: url("../../assets/Frame075.png");
}
.PosterFrame067 .PosterFrame {
  background-image: url("../../assets/Frame067.png");
}

.PosterFrame1 .PosterFrame {
  background-image: url("../../assets/Frame1.png");
}

.PosterFrameContainer img {
  position: absolute;
  width: 65%;
  object-fit: cover;
  display: flex;
  z-index: 1;
  aspect-ratio: var(--aspect-ratio);
}

.PosterFrameContainer video {
  position: absolute;
  width: 65%;
  object-fit: cover;
  display: none;
  padding: 30px;
  z-index: 1;
  aspect-ratio: var(--aspect-ratio);
}

.PosterFrameContainer span {
  position: absolute;
  z-index: 0;
  background-color: black;
  width: 60%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.PosterFrameContainer:hover img {
  display: none;
}

.PosterFrameContainer:hover video {
  display: flex;
}

.PosterFrameStay img {
  display: flex !important;
}

.PosterFrameStay video {
  display: none !important;
}

.PosterShowVide img {
  display: none !important;
}

.PosterShowVide video {
  display: flex !important;
}

.RevivarBlackIcon {
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/logoBlack.png");
  height: 25px;
  width: 25px;
  position: absolute;
  left: 5px;
}

@media only screen and (max-width: 600px) {
  .RevivarBlackIcon {
    height: 14px;
    width: 14px;
  }
}

.PlayPosterContainer {
  height: 30px;
  width: 90px;
  background-color: var(--top-sheet-color);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 20px;
  background-image: url("../../assets/playButtonBlack.png");
  cursor: pointer;
  /* transition: 0.5s; */
}

.PausePosterContainer {
  background-image: url("../../assets/pauseButtonBlack.png");
}

.SelectPosterTypeContainer {
  width: 400px;
  /* width: 93%; */
  /* height: 40px; */
  background-color: rgba(0, 0, 0, 0.344);
  background-color: #262816bd;
  margin-top: 10px;
  border-radius: 13px;
  display: flex;
  padding: 5px;
  gap: 5px;
  text-transform: capitalize;
}

.SelectPosterTypeContainerBurnaBoy {
  background-color: #6a000bbd;
}

.SelectPosterType {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 13px;
  transition: 0.5s;
  padding: 2px;
  cursor: pointer;
}
.SelectPosterType:hover {
  background-color: #221409;
}

.SelectPosterTypeSelected {
  background-color: #221409;
}

@media only screen and (max-width: 600px) {
  .SelectPosterTypeContainer {
    width: 90%;
  }

  .PosterSection {
    gap: 20px;
    padding-top: 30px;
  }

  .PosterContainer {
    height: auto;
  }

  .PosterContainerTop {
    height: auto;
  }
}

.PaginationButton {
  background-color: #000000;
  width: 200px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 57px;
  cursor: pointer;
  border: 2px solid black;
  transition: 0.5s;
  border-radius: 10px;
  margin-top: auto;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .PaginationButton {
    width: 95%;
    height: 30px;
  }
}
