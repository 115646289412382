.root {
  width: 100%;
  /* min-height: 50vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: red; */
  --controller-width: 400px;
  --controls-width: 85%;
  --sheet-color: #ffffff1b;
}

@media only screen and (max-width: 600px) {
  .root {
    --controller-width: 90vw;
    --controls-width: 95vw;
  }
}

.VideoTop {
  width: 100%;
  height: 100vh;
  margin-bottom: 20px;
}

.PolaroidPreview {
  width: 95%;
  min-height: 400px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* background-color: red; */
  margin-bottom: 100px;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .PolaroidPreview {
    margin-top: 10px;
  }
}
.PolaroidImmersedContainer {
  width: 100%;
  height: 400px;
}

@media only screen and (max-width: 600px) {
  .PolaroidImmersedContainer {
    height: 350px;
    margin-top: 20px;
  }
}

.VideoFibre {
  background-color: rgb(0, 87, 134);
  width: 360px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/loadingImage.png");
}

.VideoFibreSlide {
  background-color: rgb(134, 94, 0);
  width: 0px;
  height: 0px;
  position: absolute;
}

.ImageFibre {
  background-color: rgb(177, 0, 0);
  width: 360px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.polaroidButton {
  width: var(--controller-width);
  height: 35px;
  background-color: white;
  border: none;
  border-radius: 10px;
  /* margin-top: 10px; */
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: var(--font-weight);
  font-size: 15px;
}

.polaroidButtonMemo {
  background-color: rgba(255, 255, 255, 0.063);
  color: rgb(255, 254, 254);
}

.polaroidButtonDisabled {
  background-color: rgba(255, 255, 255, 0.615);
  color: black;
  cursor: not-allowed;
}

.sliderContainer {
  width: var(--controller-width);
  background-repeat: no-repeat;
  height: 40px;
  background-position: center;
  /* background-color: red; */
  display: flex;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  border-radius: 10px;
  opacity: 0.95;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 25px;
  border-radius: 10px;
  /* border: 7px solid #6c6c6c; */
  background: #ffffff;
  cursor: pointer;
}

.DoubleButtonStack {
  display: flex;
  width: var(--controller-width);
  gap: 10px;
  /* background-color: red; */
  /* min-height: 10px; */
}

.playButton {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  height: 40px;
  width: 40px;
  background-image: url("../../assets/playButtton.png");
  cursor: pointer;
  padding: 17px;
  background-color: var(--sheet-color);
  border-radius: 13px;
  width: 100px;
  margin-top: 10px;
  transition: 0.5s;
}

.playButton:hover {
  background-color: #ffffff34;
}

.pausePlayButton {
  background-image: url("../../assets/pauseButton.png");
}

.ControlsStacker {
  background-color: #ffffff25;
  border-radius: 17px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  --font-weight: bold;
}

.ControlsStacker u {
  font-size: 12px;
  cursor: pointer;
  color: #ffffff8b;
}

.IntroBodyProps {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: auto;
}

.IntroBodyProps h1 {
  margin: 0px;
}

.IntroBodyProps span {
  margin-bottom: 50px;
}

.UploadMessage {
  max-width: var(--controller-width);

  height: 105px;
  background-color: rgba(255, 255, 255, 0.07);
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  padding: 10px;
  resize: none;
  resize: vertical;
  font-family: monospace;
}

.canvasPreview {
  background-color: red;
  padding: 20px;
}

.addNoteButton {
  width: 25px;
  height: 25px;
  background-color: black;
  border: 2px solid white;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  cursor: pointer;
}

.addNoteButtonTick {
  width: 100%;
  height: 100%;
  background-color: #000000;
  border-radius: 4px;
}

.addNoteButtonUnTick {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 4px;
}

.addNoteButtonContainer,
.imageDPIContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  font-size: 14px;
  white-space: pre-line;
  flex-direction: column;
}

.addNoteButtonContainer {
  flex-direction: row;
}

.imageDPIContainerWarning {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 30px;
  width: 30px;
  background-image: url("../../assets/Icons/WarningIcon.png");
}
