.root {
  width: 100%;
  height: 200vh;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  color: white;
  background-color: #000000b9;
  padding: 30px;
}

.MainTitle {
  text-align: left;
  width: 85%;
  font-size: 3em;
  margin-bottom: 0;
}

.ApplyCarouselContainer {
  display: grid;
  width: 50%;
  height: 500px;
}

.ApplyCarouselContainer h1 {
  text-align: left;
  width: 85%;
  font-size: 2.75em;
  line-height: 105%;
}

.ApplyCarouselNavigation {
  display: flex;
  gap: 50px;
}

.prevButton,
.nextButton {
  border-radius: 13px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  height: 35px;
  cursor: pointer;
}

.prevButton {
  border-radius: 50%;
  width: 35px;
}

.nextButton {
  width: 175px;
}

.ApplyAssets {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.ApplyAsset1,
.ApplyAsset2,
.ApplyAsset3,
.ApplyAsset4 {
  background: #ffffff;
  padding-top: 4%;
  aspect-ratio: 7 / 8;
}

.ApplyAsset1 {
  width: 300px;
  position: relative;
  z-index: 4;
  order: 1;
}

.ApplyAsset2 {
  width: 275px;
  transform: translateX(-50%);
  position: relative;
  z-index: 3;
  order: 2;
}

.ApplyAsset3 {
  width: 250px;
  transform: translateX(-115%);
  position: relative;
  z-index: 2;
  order: 3;
}

.ApplyAsset4 {
  width: 225px;
  transform: translateX(-190%);
  position: relative;
  z-index: 1;
  order: 4;
}

.ApplyAssetImage {
  width: 85%;
  height: 75%;
  margin: 0 auto;
  background-size: cover;
}

.Logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/logowhite.png");
  height: 50px;
  width: 50px;
  margin-bottom: 20px;
}

.Form {
  width: 100%;
}

.Form input {
  border: none;
  padding-left: 20px;
  font-size: 20px;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff15;
  color: white;
  font-family: Nunito;
  height: 40px;
  margin: 4px 0;
}

.Form textarea {
  border: none;
  padding-left: 20px;
  padding-top: 7px;
  font-size: 20px;
  width: 100%;
  border-radius: 10px;
  height: 52px;
  color: white;
  font-family: Nunito;
  resize: vertical;
  background-color: #ffffff15;
  margin: 4px 0;
}

.Form ::placeholder {
  color: #ffffff53;
  opacity: 1; /* Firefox */
}

.Form button {
  padding: 0 50px;
  border-radius: 13px;
  margin: 0 auto;
  color: #000000;
  background-color: #ffffff88;
  height: 40px;
  font-size: 20px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .ApplyCarouselContainer {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .ApplyCarouselContainer h1 {
    width: 75%;
    margin-top: unset;
  }

  .ApplyAssets {
    overflow: hidden;
    width: 100%;
  }

  .ApplyAsset2 {
    display: none;
    transform: translateX(-75%);
  }

  .Form textarea {
    width: 85%;
  }
}

.sent {
  width: 100%;
  background-color: white;
  color: black;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
}
