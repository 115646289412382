.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  justify-self: center;
  --radius: 13px;
  --button-color: #ffffff17;
  --sheet-color: #ffffff1b;
  margin-bottom: 300px;
}

.AccountContentContainer {
  min-height: var(--min-height);
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px;
  --width: 100%;
}

.UpdateOrderDetails {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  font-weight: bold;
}

.UpdateOrderDetailsList {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #ffffff18;
  padding: 10px;
  flex-wrap: wrap;
  border-radius: 13px;
  justify-content: center;
}

.UpdateOrderStatusButton {
  width: 170px;
  height: 35px;
  display: flex;
  background-color: #ffffff26;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
}

.ProductListContainer {
  margin-top: 40px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.AdminEventContainer {
  display: flex;
  flex-direction: column;
}

.AdminPreviewProductContainer {
  width: 300px;
  height: 400px;
  background-color: rebeccapurple;
  display: flex;
  border-radius: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  position: relative;
}

.AdminPreviewProductContainer img {
  width: 40px;
  position: relative;
  z-index: 1;
}

.AdminPreviewProductContainer video {
  position: absolute;
  width: 300px;
  height: 400px;
  pointer-events: none;
}

.ActionButtonsContainer {
  display: flex;
  gap: 10px;
  padding: 15px 0;
  margin: 0 auto;
  justify-content: center;
}

.EditActionButton {
  width: 140px;
  height: 40px;
  display: flex;
  background-color: #ffffff26;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: none;
  flex: 1;
}

.DeleteActionButton {
  width: 140px;
  height: 40px;
  display: flex;
  color: #691822;
  background-color: #000000a0;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  flex: 1;
}

.DisableActionButton {
  width: 140px;
  height: 40px;
  display: flex;
  background-color: white;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
  color: black;
  border: none;
  flex: 1;
}

.EditEventContainer {
  display: flex;
  gap: 32px;
  width: 100%;
  align-items: center;
  width: var(--width);
  margin: 20px auto;
}

.EditEventImageContainer {
  --width: 40%;
  width: var(--width);
  height: 525px;
  background-color: black;
  display: flex;
  border-radius: 19.5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.EditEventImageContainer div {
  padding: 10px 20px;
  background-color: black;
  border-radius: 10px;
  font-size: 1.15em;
  cursor: pointer;
  position: relative;
}

.EditEventVideo {
  width: 100%;
  height: 525px;
  position: absolute;
}

.EditUserEventContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #000000a0;
  border-radius: 13px;
  padding: 20px;
}

.EditEventFieldsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  --controller-width: 100%;
}

.EditEventField {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  gap: 24px;
}

.EditEventField input {
  border: none;
  padding-left: 10px;
  font-size: 20px;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff08;
  color: white;
  font-family: Nunito;
  height: 40px;
}

.EditEventField ::placeholder {
  color: #ffffff53;
  opacity: 1; /* Firefox */
}

.ReactDatePicker {
  font-size: 20px;
  width: 100%;
  background-color: #ffffff0a;
  color: white;
  font-family: Nunito;
}

.ReactDatePicker svg {
  stroke: white;
}

.ReactDatePickerCalendar {
  border: none;

  border: 2px solid rgba(255, 255, 255, 0.386) !important;
  padding: 10px 20px;
  font-size: 20px;
  width: 100%;
  border-radius: 10px;
  background-color: #000000 !important;
  color: white;
  font-family: Nunito !important;
  margin-bottom: 20px;
}

.ReactDatePicker input {
  background: unset !important;
  outline: none;
  padding-left: 0px;
  text-align: center;
}

.EditEventField textarea {
  border: none;
  padding-left: 10px;
  font-size: 20px;
  width: 95%;
  border-radius: 10px;
  height: 50px;
  color: white;
  font-family: Nunito;
  resize: vertical;
  background-color: #ffffff08;
}

.EditEventFieldsButton {
  border: none;
  font-size: 14px;
  line-height: 14px;
  width: 250px;
  border-radius: 10px;
  background-color: var(--button-color);
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
  transition: 0.5s;
}

.EditEventFieldsButton:hover {
  background-color: #ffffff;
  color: #000000;
}

.EditUserEventContainer button {
  background: #ffffff08;
}

.EditEventFieldsContainer button:hover {
  background-color: #ffffff;
  color: #000000;
}

.EditEventFieldRange {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  gap: 16px 24px;
  flex-wrap: wrap;
}

.EditEventFieldRange input {
  display: none;
}

.EditEventFieldRange label {
  border: none;
  padding: 12.5px 0;
  font-size: 18px;
  width: 100%;
  border-radius: 15px;
  background-color: #ffffff26;
  color: white;
  display: block;
  cursor: pointer;
}

.EditEventField input:checked + label {
  color: #000000;
  background-color: white;
}

.EditVideCoverContainer {
  width: 40%;
  height: 525px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 19.5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.EditVideCoverContainer div {
  padding: 10px 20px;
  background-color: black;
  border-radius: 10px;
  font-size: 1.15em;
  cursor: pointer;
  position: relative;
}

.EditVideCoverContainer p {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  padding: 2.5px 5%;
  align-self: flex-start;
}

.EditVideoCoverImage {
  width: 90%;
  height: 400px;
  background-color: rebeccapurple;
  display: flex;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;
  position: relative;
  overflow: hidden;
}

.ExtraPicturesContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 25px;
  background-color: #ffffff0d;
  padding: 10px;
  border-radius: 10px;
}

.ExtraPicturesContainer p {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
  margin-top: 10px;
}

.RelatedImage {
  display: grid;
  width: calc(25% - 20px);
  height: 300px;
  justify-items: end;
  background-position: center;
  background-size: cover;
  border-radius: 13px;
}

@media only screen and (max-width: 800px) {
  .ExtraPicturesContainer {
    gap: 20px;
  }

  .RelatedImage {
    width: calc(50% - 10px);
    height: 175px;
  }

  .EditEventContainer {
    display: grid;
  }

  .EditEventField {
    display: grid;
    justify-content: unset;
  }

  .EditEventFieldsButton {
    width: 100%;
  }
}

.RemoveRelatedImage {
  cursor: pointer;
  position: relative;
  left: 18px;
  width: 36px;
  bottom: 18px;
  background: white;
  height: 36px;
  border-radius: 50%;
}

.EditEventProductSelector {
  min-width: 100px;
  height: 36px;
  background-color: var(--button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: 0.5s;
  text-transform: capitalize;
  padding-left: 8px;
  padding-right: 8px;
}

.EditEventProductSelector:hover,
.EditEventProductSelected {
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
}

.EditEventProductSelectorContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.EditEventProductDefault {
  border: 3px solid rebeccapurple;
}

.PosterContainer {
  width: 100%;
  height: 50vh;
}

.PosterContentContainer {
  width: 50%;
  padding: 10px;
  border-radius: 13px;
}

@media only screen and (max-width: 800px) {
  .PosterContentContainer {
    width: 100%;
  }
}

.AddRelatedImage {
  width: calc(25% - 20px);
  background: #ffffff0a;
  display: grid;
  place-content: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}

.AddRelatedImage:hover {
  background: #ffffff15;
}

.AddRelatedImage img {
  width: 36px;
  background: white;
  height: 36px;
  border-radius: 50%;
}

.EventExpiry {
  width: 100%;
  color: #691822;
  font-size: 18px;
  cursor: default;
}

.EventExpiry a {
  color: #ffffff;
  display: inline-block;
  padding-left: 1ch;
}

.DeletePopup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #000000b3;
  z-index: 100;
  cursor: pointer;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.DeleteConfirmationPopup {
  width: 500px;
  min-height: 200px;
  background-color: #000000;
  border-radius: 30px;
  border: 5px solid #ffffff59;
  padding: 30px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DeletePopup p {
  font-weight: 800;
  font-style: italic;
  font-size: 35px;
  margin: 0;
  line-height: 100%;
}

.DeletePopup button {
  width: 80%;
  font-size: 16px;
}

.UploadIcon {
  width: 66px;
  background: #000000ab;
  padding: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.EventMediaAssetItem {
  background-color: transparent;
}

.EditMediaActions {
  display: flex;
  gap: 10px;
}

.DownloadStickerTag {
  width: 100%;
  height: 37px;
  background-color: white;
  border-radius: 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
