.Revivart2D {
  /* width: 350px; */
  /* height: 350px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: rebeccapurple; */
  --width: 340px;
  --inner-width: ;
  --asset-width: 200px;
  --asset-height: 300px;
  --poster-image-offset: 84px;
}

.PosterContentHorizontal {
  --asset-width: 300px;
  --asset-height: 200px;
  --poster-image-offset: 50px;
}

.PosterContainer {
  display: flex;
  align-items: start;
  justify-content: center;
}

.PosterContent {
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
  perspective: 100px;
  perspective-origin: left;
  z-index: 1;
}

.PosterContent video {
  object-fit: cover;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/loadingImage.png");
}

.PosterContentImage {
  position: absolute;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  z-index: 1;
}

.PosterContentExtra {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* GREETING */
.GreetingCardType .PosterContent {
  width: var(--asset-width);
  height: var(--asset-height);
}

.GreetingCardType .PosterContentImage,
.GreetingCardType video {
  width: 100%;
  height: 100%;
}

.GreetingCardType .PosterContentExtra {
  background-color: rgb(255, 255, 255);
  width: 80%;
  top: -7px;
  left: 0px;
  transform: rotateY(-3deg);
}

.PosterContentHorizontal .GreetingCardType .PosterContentExtra {
  width: 100%;
  height: 80%;
  top: -1px;
  right: -4px;
  left: unset;
  transform: unset;
  transform: rotateX(1deg);
}

.GreetingCardType .PosterContentHorizontal {
  display: none;
}

/* POSTCARD */
.PostCardType .PosterContent {
  width: var(--asset-width);
  height: var(--asset-height);
  background-color: white;
}

.PostCardType .PosterContentImage,
.PostCardType video {
  width: 100%;
  height: 100%;
}

/* POLAROID */
.PolaroidType .PosterContent {
  width: 300px;
  height: 340px;
  background-color: white;
}

.PolaroidType .PosterContentImage,
.PolaroidType video {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 280px;
  height: 280px;
}

/* POSTER */
.PosterFrame08 {
  --aspect-ratio: 0.8;
}

.PosterContentHorizontal .PosterFrame075 {
  --aspect-ratio: calc(1 / 0.8);
}

.PosterFrame075 {
  --aspect-ratio: 0.75;
}

.PosterContentHorizontal .PosterFrame075 {
  --aspect-ratio: calc(1 / 0.75);
}

.PosterFrame067 {
  --aspect-ratio: 0.67;
}

.PosterContentHorizontal .PosterFrame067 {
  --aspect-ratio: calc(1 / 0.67);
}

.PosterFrame1 {
  --aspect-ratio: 1;
}

.PosterType .PosterContent {
  width: 340px;
  /* height: 340px; */
  align-items: center;
  justify-content: center;
  aspect-ratio: var(--aspect-ratio);
}

.PosterType .PosterContentImage,
.PosterType video {
  width: calc(100% - var(--poster-image-offset));
  height: calc(100% - var(--poster-image-offset));
}

.PosterType .PosterContentExtra {
  z-index: 10;
  left: -2px;
}

.PosterFrame08 .PosterContentExtra {
  background-image: url("../../assets/Frame08.png");
}

.PosterContentHorizontal .PosterFrame08 .PosterContentExtra {
  background-image: url("../../assets/Frame08-Rotated.png");
}

.PosterFrame075 .PosterContentExtra {
  background-image: url("../../assets/Frame075.png");
}

.PosterContentHorizontal .PosterFrame075 .PosterContentExtra {
  background-image: url("../../assets/Frame075-Rotated.png");
}

.PosterFrame067 .PosterContentExtra {
  background-image: url("../../assets/Frame067.png");
}

.PosterContentHorizontal .PosterFrame067 .PosterContentExtra {
  background-image: url("../../assets/Frame067-Rotated.png");
}

.PosterFrame1 .PosterContentExtra {
  background-image: url("../../assets/Frame1.png");
}

@media only screen and (max-width: 800px) {
  .Revivart2D {
    --asset-width: 100px;
    --asset-height: 150px;
    --poster-image-offset: 30px;
  }

  .PosterContentHorizontal {
    --asset-width: 150px;
    --asset-height: 100px;
    --poster-image-offset: 25px;
  }

  .GreetingCardType .PosterContentExtra {
    background-color: rgb(255, 255, 255);
    top: -2px;
  }

  /* POSTCARD */
  .PostCardType .PosterContent {
    background-color: white;
  }

  /* POLAROID */
  .PolaroidType .PosterContent {
    width: 170px;
    height: 190px;
    background-color: white;
  }

  .PolaroidType .PosterContentImage,
  .PolaroidType video {
    width: 150px;
    height: 150px;
  }

  /* POSTER */
  .PosterType .PosterContent {
    width: 170px;
  }
}
