.BlackFridayBar,
.ValentinesBar {
  height: 40px;
  overflow: hidden;
  border: 2px solid white;
  border-style: none none solid none;
  background-color: #000000a8;
  cursor: pointer;
}

.BlackFridayBarAnimate,
.ValentinesBarAnimate {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: banner;
  gap: 10px;
}

.BlackFridayBarAnimate span {
  white-space: nowrap;
  margin-right: 0px;
}

.BlackFridayBarShop {
  min-width: 100px;
  height: 27px;
  background-color: rgb(255, 255, 255);
  color: black;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  border: 3px solid white;
  border-radius: 30px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .BlackFridayBarAnimate {
    font-size: 12px;
    gap: 5px;
  }

  .BlackFridayBarShop {
    height: 20px;
  }
}

.CartContainerButtonIcon {
  height: 100%;
  aspect-ratio: 1;
}

@keyframes banner {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
