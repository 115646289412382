.root {
  width: 100vw;
  min-height: 100vh;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  --radius: 10px;
  --width: 90%;
  gap: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.orderCompleteRoot {
  /* width: 100vw; */
  height: 100%;
  /* background-color: rgb(218, 0, 0); */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.orderCompleteLeft {
  width: 50vw;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.orderCompleteTitle {
  font-weight: bold;
  font-size: 40px;
  height: 30px;
}

.orderCompleteRight {
  width: 50vw;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.OrderCompleteSummary {
  width: 400px;
  background-color: #ffffff15;
  margin-top: 50px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.OrderCompleteSummary h3 {
  line-height: 20px;
  margin: 0px;
}

.OrderCompleteSummarySection {
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  background-color: #ffffff23;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
}

.OrderCompleteLine {
  width: 90%;
  display: flex;
  text-align: right;
  overflow: hidden;
}

.OrderCompleteLine b {
  margin-right: auto;
  text-align: left;
  width: 30%;
}

.OrderCompleteLine span {
  margin-right: auto;
  text-align: right;
  width: 70%;
}

.OrderShippingContainer,
.CartContainer {
  width: var(--width);
  min-height: 40px;
  background-color: #ffffff08;
  border-radius: var(--radius);
  /* margin-top: 35px; */
  padding: 20px;
  font-size: 25px;
  text-align: left;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.OrderShippingContainer b,
.CartContainer b {
  margin-right: auto;
}

.OrderShippingContainer span {
  font-size: 14px;
  text-align: start;
  width: 100%;
  color: red;
}

.CartItemBar {
  width: 100%;
  height: 150px;
  background-color: #ffffff12;
  border-radius: var(--radius);
  padding: 10px;
  gap: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  transition: 0.5s;
}

.CartItemBar:hover {
  background-color: #ffffff22;
}

.CartItemBar select {
  background-color: red;
  border: none;
  width: 50%;
  height: 25%;
  border-radius: var(--radius);
  background-color: #121212;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .CartItemControls select {
    width: 200%;
  }
}

.CartItemImage {
  width: 150px;
  height: 100%;
  /* background-color: rgb(0, 0, 0); */
  border-radius: var(--radius);
  object-fit: cover;
  border: 1px solid white;
}

.CartItemInfo {
  width: 50%;
  height: 100%;
  /* background-color: rgb(60, 0, 0); */
  border-radius: var(--radius);

  font-size: 18px;
  padding-top: 10px;
  gap: 2px;
  display: flex;
  flex-direction: column;
}

.CartItemControls {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  border-radius: var(--radius);
  margin-left: auto;
  width: 10%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.CartItemDeleteIcon {
  height: 20px;
  width: 20px;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/DeleteIcon.png");
  cursor: pointer;
}

.CartContainerEmptyIcon {
  height: 400px;
  width: 350px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/Icons/EmptyCartIcon.png");
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  .CartContainerEmptyIcon {
    height: 400px;
    width: 60%;
  }
}

.CartItemControls input {
  width: 50%;
  height: 25%;
  border-radius: var(--radius);
  background-color: #121212;
  border: 1px solid white;
  color: white;
  text-align: center;
}

.CartItemControlsPreviewAmount {
  width: 80px;
  height: 25%;
  border-radius: var(--radius);
  background-color: #121212;
  color: white;
  text-align: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .CartItemControlsPreviewAmount {
    margin-top: auto;
  }
}

.CartContainerTotal {
  width: 100%;
  padding: 15px;
  background-color: #ffffff08;
  border-radius: var(--radius);
  transition: 0.5s;
}

.CartContainerTotal:hover {
  background-color: #ffffff12;
}

.CartContainerTotalContainer {
  width: 90%;
  /* height: 150px; */
  /* margin-top: 20px; */
  padding: 15px;
  border-radius: var(--radius);

  background-color: #ffffff0c;
}

.CartContainerTotalItem {
  height: 20%;
  font-size: 20px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}

.CartContainerControls {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CartContainerButton {
  height: 35px;
  font-size: 17px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  background-color: white;
  padding: 5px;
  color: black;
  border: 1px solid white;
  cursor: pointer;
  transition: 0.5s;
}
.CartContainerButton:hover {
  background-color: rgb(255, 255, 255);
}

.CartContainerButtonIcon {
  height: 100%;
  aspect-ratio: 1;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/checkoutSpinner.png");
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 800px) {
  .orderCompleteRoot {
    flex-direction: column;
  }
  .orderCompleteLeft {
    width: 100vw;
  }

  .orderCompleteRight {
    width: 100vw;
  }

  .OrderCompleteSummary {
    width: 90%;
    margin-bottom: 50px;
  }

  .CartItemControls input {
    width: 100%;
  }

  .CartContainerControls {
    flex-direction: column-reverse;
    gap: 100px;
    justify-content: center !important;
    align-items: center !important;
  }

  .CartContainerButton {
    width: 100%;
  }
}

.OrderShippingInfoContainer {
  width: 100%;
  min-height: 100px;
  /* background-color: red; */
  /* display: flex; */
  gap: 20px;
}

.OrderShippingInfoContainerSplit {
  gap: 15px;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.OrderShippingInfoItem {
  width: 100%;
  min-height: 60px;
  /* background-color: rebeccapurple; */
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.OrderShippingInfoItem b {
  margin-left: 5px;
}

.OrderShippingInfoItem select,
.OrderShippingInfoItem input {
  background-color: #121212;
  border: none;
  height: 40px;
  border-radius: var(--radius);
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.OrderShippingInfoItem input::-webkit-outer-spin-button,
.OrderShippingInfoItem input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.OrderShippingAddressPreview,
.OrderShippingMethodItem {
  width: 100%;
  border-radius: var(--radius);
  font-size: 16px;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #ffffff12;
}
.OrderShippingMethodItemRadio {
  height: 20px;
  width: 20px;
  background-color: #050505;
  border: 10px solid white;
  border-radius: 15px;
}

.OrderShippingMethodItemRadioSelected {
  border: 5px solid white;
}

.OrderShippingAddressPreview {
  font-weight: 500;
  text-transform: capitalize;
}

.OrderCouponItem {
  border-radius: var(--radius);
  width: 300px;
  height: 40px;
  transition: 0.5s;
  background-color: transparent;
  border: none;
  color: white;
  padding-left: 10px;
  font-weight: bold;
  font-size: 16px;
}

.OrderCouponItem:hover {
  background-color: #0000006d;
}

::placeholder {
  opacity: 1;
  color: white;
}

.OrderCouponItemContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  background-color: #ffffff08;
  border-radius: var(--radius);
  padding: 10px;
}

.OrderCouponItemText {
  width: 100px;
  color: rgb(86, 86, 255);
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
}
.OrderCouponItemText:hover {
  color: rgb(17, 17, 255);
}

.OrderCouponItemTextEnd {
  width: 100%;
  color: #ffffff5c;
  font-weight: bold;
  cursor: pointer;
  text-transform: capitalize;
  text-align: start;
}

.CartContainerButtonPay {
  width: 100%;
  border: none;
  margin-top: 20px;
  height: 38px;
  border-radius: var(--radius);
  cursor: pointer;
  font-size: 18px;
  font-weight: bolder;
}

.CartContainerButtonPay span {
  color: black;
}

.CheckoutForm {
  width: 100%;
}

.CartCheckoutPaymentContainer {
  width: var(--width);
  min-height: 40px;
  background-color: #ffffff08;
  border-radius: var(--radius);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  transition: 0.5;
}

.CartCheckoutPaymentContainer b {
  font-size: 30px;
}

.CartCheckoutPayment {
  width: 100%;
  background-color: #ffffff08;
  border-radius: var(--radius);
  padding: 20px;
  color: black;
}

.ConfirmShippingAddressContainer {
  width: 100%;
  background-color: #ffffff08;
  border-radius: var(--radius);
  padding: 20px;
  color: white;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: 0.5s;
  border: 2px solid #ffffff00;
  cursor: pointer;
}

.ConfirmShippingAddressContainerSelected {
  border: 2px solid #ffffff78;
}

.ConfirmShippingAddressContainer b {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.ConfirmShippingAddressContainerText {
  background-color: #ffffff08;
  border-radius: var(--radius);
  padding: 20px;
}

.ConfirmShippingAddressContainerMap {
  width: 100%;
  height: 200px;
  border-radius: var(--radius);
  overflow: hidden;
  margin-top: 10px;
}

.ConfirmShippingMarker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConfirmShippingAddressContainerButtonSelected,
.ConfirmShippingAddressContainerButton {
  height: 20px;
  width: 20px;
  border-radius: 15px;
  background-color: white;
  border: 5px solid rgb(255, 255, 255);
}

.ConfirmShippingAddressContainerButtonSelected {
  border: 5px solid rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
}

.OrderShippingContainerButton {
  display: flex;
  gap: 10px;
  width: var(--width);
}

.BackButtonIcon {
  height: 18px;
  width: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/backArrow.png");
  filter: invert(100);
}

.BackButtonContainer {
  width: var(--width);
  display: flex;
  gap: 5px;
  font-weight: bold;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  transition: 0.5s;
}

.BackButtonContainer:hover {
  gap: 10px;
}
