.root {
  width: 100vw;
  min-height: 100vh;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  --radius: 10px;
  --radius: 13px;
  --width: 90%;
  --sheet-layer-color: #000000a0;
}

.OrderCompleteRoot {
  height: 100%;
  background-color: var(--sheet-layer-color);
  display: flex;
  border-radius: var(--radius);
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.OrderCompleteRoot b {
  font-size: 25px;
}

.OrderCompleteSummary {
  width: 100%;
  background-color: #ffffff15;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 10px;
}

.OrderCompleteSummary h3 {
  line-height: 20px;
  margin: 0px;
}

.OrderCompleteSummarySection {
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  background-color: #ffffff23;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
}

.OrderCompleteLine {
  width: 100%;
  display: flex;
  text-align: right;
  overflow: hidden;
  font-size: 15px;
}

.OrderCompleteLine b {
  margin-right: auto;
  text-align: left;
  width: 30%;
  font-size: 15px;
}

.OrderCompleteLine span {
  margin-right: auto;
  text-align: right;
  width: 70%;
}

.CartItemBar {
  height: 150px;
  width: 100%;
  background-color: #ffffff15;
  display: flex;
  border-radius: var(--radius);
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.CartItemBar:hover {
  background-color: #ffffff22;
}

.CartItemImage {
  width: 150px;
  height: 100%;
  background-color: rgb(0, 0, 0);
  border-radius: var(--radius);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.CartItemInfo {
  width: 50%;
  height: 100%;
  border-radius: var(--radius);
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.CartItemInfo div {
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100%;
  text-align: start;
}

@media only screen and (max-width: 600px) {
  .CartItemInfo {
    width: 70%;
    font-size: 15px;
  }
}

.OrderCompleteDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
}

.OrderCompleteDetails span {
  margin-left: auto;
  font-weight: bold;
  text-transform: uppercase;
}

.OrderCompleteDetailsTitle {
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .OrderCompleteDetails {
    text-align: start;
  }
}
