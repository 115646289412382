body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-date-picker {
  padding: unset !important;
}

.react-date-picker__wrapper {
    border: unset !important;
}

.react-date-picker__wrapper:has(input:focus) {
    outline: thin solid gray !important;
    border-radius: 20px;
}

.react-date-picker__inputGroup {
    display: flex;
    align-items: baseline;
}

.react-date-picker__button {
  width: unset !important;
  padding: 0 11px !important;
  border-radius: unset !important;
}

.react-calendar {
  background: #080808 !important;
}

.react-calendar abbr {
  color: #757575;
}

.react-calendar span {
  color: #b7b7b7;
}

.react-calendar button {
  color: #9b9a9a;
}
