.Root {
  width: 100%;
  min-height: 900px;
  background-color: #ffffff18;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
  font-size: 0px;
}

.Root h1 {
  font-size: 60px;
}

.DeckList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.DeckList span {
  width: 100%;
  height: 50vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  overflow: hidden;
}

.deckImage1 {
  border-radius: 20px;
  background-image: url("./deckImages/DECK1-reduced_Page_01.jpg");
}

.deckImage2 {
  background-image: url("./deckImages/DECK1-reduced_Page_02.jpg");
}

.deckImage3 {
  background-image: url("./deckImages/DECK1-reduced_Page_03.jpg");
}

.deckImage4 {
  background-image: url("./deckImages/DECK1-reduced_Page_04.jpg");
}

.deckImage5 {
  background-image: url("./deckImages/DECK1-reduced_Page_05.jpg");
}

.deckImage6 {
  background-image: url("./deckImages/DECK1-reduced_Page_06.jpg");
}

.deckImage7 {
  background-image: url("./deckImages/DECK1-reduced_Page_07.jpg");
}

.deckImage8 {
  background-image: url("./deckImages/DECK1-reduced_Page_08.jpg");
}

.deckImage9 {
  background-image: url("./deckImages/DECK1-reduced_Page_09.jpg");
}

.deckImage10 {
  background-image: url("./deckImages/DECK1-reduced_Page_10.jpg");
}
