.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  background-color: #000000;
  min-height: 100vh;
  --max-width: 1000px;
  cursor: none;
}

.cursor {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  position: fixed;
  left: 0;
  z-index: 10;
  border: 1px solid white;
  transform: translate(-50%, -50%);
  pointer-events: none;
  background-color: #0000005d;
}

@media only screen and (max-width: 600px) {
  .root {
    cursor: auto;
  }

  .cursor {
    opacity: 0;
  }
}

.LogoText {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/logotext.png");
  height: 120px;
  width: 450px;
  margin-top: -50px;
}

.LogoImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/logowhite.png");
  height: 60px;
  width: 60px;
}

.TobennaWesLogoImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/TobennaWesLogo.png");
  height: 40px;
  width: 40px;
}

.IntroVideoContainer {
  width: 100%;
  height: 70vh;
  background-color: #3c1318;
  position: relative;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.IntroVideoContainerMain {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #00000079;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 1) 90%);
}

.IntroVideoContainer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: transparent;
}

.IntroVideoContainerMainTop {
  width: 100%;
  min-height: 20%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: auto;
}

.IntroVideoContainerMainTop .LogoImage {
  margin: 20px;
}

.IntroVideoContainerMainMid {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  /* margin-top: auto; */
}

.IntroVideoContainerMainMid span {
  width: 120px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0.149);
  border: 2px solid white;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.IntroVideoContainerMainBottom {
  width: 100%;
  padding: 10px;
  min-height: 20%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  align-items: flex-end;
}

.LargeSmallText {
  height: 200px;
  max-width: var(--max-width);
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  text-align: start;
}

@media only screen and (max-width: 600px) {
  .LargeSmallText {
    font-size: 17px;
    height: auto;
    width: 90%;
  }
}

.LargeMediumText {
  max-width: var(--max-width);
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  margin-top: 200px;
  margin-bottom: 150px;
  /* background-image: url("../../assets/MainPageBack.png");
  background-position: center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-weight: 800;
  text-align: start;
}

.FrameExplained {
  max-width: var(--max-width);
  width: 90%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 30px;
  gap: 50px;
  text-align: start;
  font-size: 20px;
  flex-direction: column;
  margin-bottom: 150px;
  font-weight: bold;
  position: relative;
  margin-top: 50px;
}

.FrameExplained .PosterFrameSpinnerContainer {
  top: 350px;
}

.FrameExplained video {
  height: 700px;
  aspect-ratio: 4/5;
  margin-bottom: 100px;
}

.FrameExplained h1 {
  font-size: 80px;
  text-align: start;
  font-weight: 900;
}

.FrameExplained span {
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .LogoText {
    width: 80%;
  }

  .FrameExplained {
    margin-bottom: 00px;
  }

  .FrameExplained video {
    height: 400px;
  }

  .FrameExplained h1 {
    font-size: 60px;
    line-height: 60px;
  }

  .FrameExplained span {
    font-size: 20px;
  }
}

.SimpleCreation {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/SimpleCreation.png");
  height: 800px;
  max-width: var(--max-width);
  width: 100%;
  margin-bottom: 200px;
  margin-top: 200px;
}

.DemoVideo {
  margin-top: 50px;
  /* margin-bottom: 100px; */
  max-width: var(--max-width);
  width: 95%;
  overflow: hidden;
  display: flex;
  gap: 50px;
  text-align: left;
  font-size: 26px;
  font-weight: 700;
}

.DemoVideo video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 40%;
  border-radius: 20px;
  overflow: hidden;
  height: 70vh;
}

.DemoVideo h1 {
  font-weight: 800;
  margin: 0px;
  line-height: 80px;
  /* background-color: red; */
  margin-bottom: 50px;
  font-size: 80px;
}

@media only screen and (max-width: 1000px) {
  .DemoVideo {
    margin-top: 200px;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
    font-size: 20px;
    width: 80%;
  }

  .DemoVideo video {
    height: 500px;
    width: 100%;
    border-radius: 20px;
  }
}

.LargeLargeText {
  height: 200px;
  max-width: var(--max-width);
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 90px;
  line-height: 90px;
  font-weight: 900;
}

@media only screen and (max-width: 600px) {
  .LargeLargeText {
    font-size: 60px;
    line-height: 60px;
    height: auto;
    margin-bottom: 50px;
    text-align: start;
  }
}

.ExamplesLarge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 30px;
  font-weight: 700;
  transition: 1s;
  flex-direction: column;
  height: 400px;
  max-height: 400px;
  max-width: 400px;
  width: calc(50vw - 20px);
  background-color: #ffffff0b;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.131);
  padding: 10px;
}

.ExamplesLarge video {
  height: 200px;
  object-fit: contain;
  z-index: 1;
}

.ExamplesLarge span {
  width: 100%;
  margin-top: 30px;
}

@media only screen and (max-width: 800px) {
  .ExamplesLarge {
    font-size: 20px;
    height: 400px;
  }

  .ExamplesLarge:hover {
    height: 500px;
  }

  .ExamplesLarge video {
    object-fit: contain;
    z-index: 1;
    width: 100%;
  }
}
.IconicText {
  height: 400px;
  margin-top: 70px;
  margin-bottom: 30px;
  max-width: var(--max-width);
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  font-weight: bold;
  font-style: italic;
}

.ReferenceVideo {
  margin-top: 50px;
  margin-bottom: 200px;
  height: 500px;
  max-width: var(--max-width);
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/postershopImage.jpg");
  overflow: hidden;
  border-radius: 20px;
}

.ReferenceVideo video {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.HistoryContentMain {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0000007c;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
  z-index: 4;
}

.HistoryContentMain .TobennaWesLogoImage {
  height: 130px;
  width: 130px;
}

.HistoryContentMain span {
  text-align: start;
}

.ShopPosterButton {
  width: 150px;
  padding: 5px;
  background-color: white;
  color: black;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}

.ViewMoreButton {
  width: 100%;
  padding: 5px;
  background-color: #ffffff1a;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.ShopPosterButtonLarge {
  height: 70px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: bold;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.5s;
}

.ShopPosterButtonLarge:hover {
  background-color: #ffffff31;
}

.AfricaContent {
  width: 70%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  font-size: 60px;
  font-weight: 800;
  margin-bottom: 200px;
  text-align: start;
}

.AfricaContentIcon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/africaIcon.png");
  height: 150px;
  width: 150px;
}

@media only screen and (max-width: 600px) {
  .LogoText {
    width: 80%;
  }

  .SimpleCreation {
    height: 300px;
  }

  .IconicText {
    font-size: 50px;
  }
}

.PosterFrameSpinnerContainer {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 5;
  border-radius: 25px;
  padding: 5px;
  backdrop-filter: blur(5px);
  background-color: #00000060;
}

.PosterFrameSpinner {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  border: 7px dotted white;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  padding: 5px;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.ExamplesContainer {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
