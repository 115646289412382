.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  justify-self: center;
  --radius: 13px;
}

.AccountContentContainer {
  min-height: var(--min-height);
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px;
}

.ApplicationItemContainer {
  text-align: left;
  margin: 20px 0;
  background-color: #ffffff12;
  padding: 20px;
  border-radius: 13px;
}

.ApplicationItemContainer p {
  margin: unset;
}

.ApplicationItemContainer a {
  color:#ffffff
}

.ApplicationItemContainer button {
  padding: 0 20px;
  border-radius: 13px;
  color: #000000;
  background-color: white;
  border: none;
  cursor: pointer;
}

.ApplicationItemHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .ApplicationItemHeader {
    display: grid;
    gap: 5px;
  }
}
