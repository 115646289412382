.root {
  width: 100%;
  min-height: var(--nav-height);
  --nav-height: 37px;
  background-color: rgb(0, 0, 0);
  --z-index-main: 50000000;
  --z-index-main-menu: 55000000;
}

.root span {
  margin-right: auto;
}

.FullScreen {
  width: 100%;
  height: var(--nav-height);
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  --nav-height: 37px;
  gap: 5px;
  transition: 1s background-color;
  z-index: var(--z-index-main-menu);
}

.MobileScreen {
  display: none;
  width: 100vw;
  height: var(--nav-height);
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  --nav-height: 37px;
  gap: 5px;
  transition: 1s background-color;
  position: relative;
}

.MainMenu {
  padding: 2px;
  height: 50px;
  width: 50px;
  border-radius: 20px;
  background-color: #00000079;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
  margin-right: 5%;
  backdrop-filter: blur(5px);
  z-index: var(--z-index-main-menu);
  position: relative;
}

@media only screen and (max-width: 600px) {
  .MobileScreen {
    display: flex;
  }

  .FullScreen {
    display: none;
  }
}

.MobileScreen .LogoText {
  position: absolute;
}

.SleekNavBar {
  background-color: #00000000;
  position: absolute;
  z-index: var(--z-index-main-menu);
}

.StickerBar {
  background-color: #00000000;
  position: fixed;
  z-index: var(--z-index-main-menu);
}

.HomeBar {
  background-color: #00000000;
  position: fixed;
  padding-top: 20px;
  width: 100%;
  z-index: var(--z-index-main-menu);
}

.BlurBar {
  background-color: #00000084;
  backdrop-filter: blur(10px);
  z-index: var(--z-index-main-menu);
}

.NavBarSection {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #441313;
}

.LogoText {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logotext.png");
  height: 40%;
  width: 120px;
  margin-right: auto;
  cursor: pointer;
  z-index: var(--z-index-main-menu);
}

.PhoneMenuClosed {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/phoneMenuClosed.svg");
  height: 50%;
  width: 40px;
  cursor: pointer;
  z-index: var(--z-index-main-menu);
}

.Logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logowhite.png");
  height: 80%;
  width: 40px;
  cursor: pointer;
  z-index: var(--z-index-main-menu);
}

.DownloadApp {
  width: 100px;
  height: 25px;
  background-color: #00d4b51a;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.SignInButton {
  width: 90px;
  height: 25px;
  background-color: #ffffffff;
  color: black;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  z-index: var(--z-index-main-menu);
}

.DownloadApp:hover {
  background-color: #00000043;
  transition: 0.5s;
}

.CartButton {
  display: flex;
  cursor: pointer;
  height: calc(var(--nav-height));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-main-menu);
}

.CartButtonCount {
  color: #000000;
  background-color: #ffffff;
  width: 20px;
  border-radius: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px;
}

.CartButtonIcon {
  background-image: url("../assets/cartIcon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 20px;
  width: 20px;
}

.ProfileButtonIcon {
  background-image: url("../assets/profileIcon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 20px;
  width: 20px;
}

video {
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .DownloadApp {
    display: none;
  }
}

.MenuScreen {
  display: none;
  top: 0px;
  width: 100vw;
  min-height: 20vh;
  background-color: #0000009a;
  position: absolute;
  z-index: var(--z-index-main);
  backdrop-filter: blur(5px);
  flex-direction: column;
  padding-top: 7%;
  gap: 10px;
  transition: 1s;
  padding-bottom: 20px;
  position: fixed;
}

.showMenu {
  display: flex;
}

.MenuScreen span {
  width: 100%;
  /* background-color: #00000068; */
  display: flex;
  align-items: center;
  font-size: 30px;
  padding-right: 20px;
  padding-left: 5%;
  cursor: pointer;
  transition: 0.5s;
}

.MenuScreen span:hover {
  padding-left: 6%;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .MenuScreen {
    padding-top: 20%;
    min-height: 80vh;
  }

  .MenuScreen span {
    font-size: 40px;
    padding-right: 10%;
  }
}

.AccountMenuItem {
  margin-top: auto;
}

.MenuItemChildren {
  margin-left: 5%;
  font-size: 20px !important;
}

@media only screen and (max-width: 600px) {
  .MenuItemChildren {
    margin-left: 10%;
    font-size: 30px !important;
  }
}
