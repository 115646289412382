.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  --border-radius: 15px;
  --top-sheet-color: #fdfdfd15;
}

.root select {
  color: white;
}

.SectionVideoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin-top: 25px;
}

@media only screen and (max-width: 800px) {
  .SectionVideoContainer {
    height: auto;
  }
}

.SectionVideoContentContainer {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
}

@media only screen and (max-width: 800px) {
  .SectionVideoContentContainer {
    padding: 5%;
  }
}

.HeaderSectionLogo {
  width: 90px;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logowhite.png");
}

.SectionVideoContentMidContainer {
  width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* padding-top: 3%; */
  gap: 10px;
}

@media only screen and (max-width: 800px) {
  .SectionVideoContentMidContainer {
    width: 100%;
  }
}

.HeaderTitle {
  text-align: start;
  font-size: 130px;
  line-height: 130px;
  font-weight: 900;
  font-style: italic;
  margin-top: 30px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .HeaderTitle {
    font-size: 80px;
    line-height: 80px;
  }
}

.HeaderSubTitle {
  text-align: start;
}

.HeaderSearch {
  margin-top: auto;
  width: 90%;
  height: 35px;
  background-color: #ffffff0f;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: 2px solid white;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
}

.EventsSearchIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/SearchIcon.png");
}

.EventsListTitle,
.UpcomingEventsListTitle {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-top: 70px;
  display: flex;
  position: relative;
  width: 90%;
  align-items: center;
  justify-content: center;
}

.UpcomingEventsListTitle {
  margin-top: unset;
}

.ReminderButton {
  color: #000000;
  background-color: #ffffff;
  width: 25%;
  border-radius: 13px;
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}

.ReminderButtonDisabled {
  color: #691822;
  background-color: #000000a0;
  width: 25%;
  border-radius: 13px;
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}

.ReminderPopup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #00000099;
  z-index: 100;
  cursor: pointer;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReminderEmailPopup {
  width: 360px;
  min-height: 200px;
  background-color: #000000;
  border-radius: 30px;
  border: 5px solid #ffffff59;
  padding: 30px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReminderPopup p {
  font-weight: 800;
  font-style: italic;
  font-size: 35px;
  margin: 0;
  line-height: 100%;
}

.ReminderPopup button {
  width: 80%;
  font-size: 16px;
}

.ReminderPopup input {
  border: none;
  padding-left: 10px;
  font-size: 20px;
  width: 80%;
  border-radius: 10px;
  background-color: #ffffff18;
  color: white;
  font-family: Nunito;
  height: 40px;
}

@media only screen and (max-width: 800px) {
  .EventsListTitle {
    font-size: 20px;
    line-height: 80px;
  }

  .UpcomingEventsListTitle {
    margin-top: 70px;
    font-size: 20px;
  }

  .ReminderButton,
  .ReminderButtonDisabled {
    width: 90%;
  }
}

.EventSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.EventsList {
  width: 90%;
  min-height: 500px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 50px;
}

@media only screen and (max-width: 800px) {
  .EventsList {
    justify-content: center;
  }
}

.EventItem {
  width: 300px;
  height: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.EventItemImage {
  height: 90%;
  width: 100%;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

/* Event page styles */

.EventsDetail {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.EventDetailUploadHeader,
.EventDetailHeader {
  height: 20vh;
  width: 95%;
  margin-top: 20px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.EventDetailUploadHeader {
  height: 15vh;
}

.EventDetailUploadHeader span,
.EventDetailHeader span {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.614);
}

.EventDetailHeaderLocked {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.EventDetailHeaderLockedMain {
  height: 100%;
  width: 100%;
  padding: 0 2.5%;
  overflow: hidden;
  padding-top: 20px;
  backdrop-filter: blur(20px);
  background-color: #000000a4;
}

.EventDetailUploadHeader span,
.EventDetailHeader span {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  font-size: 70px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  backdrop-filter: blur(5px);
  color: #ffffff;
  border: black;
}

.EventInformation {
  /* height: 20vh; */
  width: 95%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .EventInformation {
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
  }
}

.EventInformationTitleSection {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 20px;
}

.EventInformationTitleSection span {
  font-size: 70px;
  line-height: 60px;
  font-weight: 800;
  font-style: italic;
}

@media only screen and (max-width: 800px) {
  .EventInformationTitleSection {
    font-size: 10px;
    width: 100%;
  }

  .EventInformationTitleSection span {
    font-size: 50px;
    line-height: 50px;
  }
}

.EventPriceInformation {
  width: 100%;
  display: flex;
  margin-left: 5%;
  gap: 5px 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.UserEventUploadAssetButton,
.EventInformationTitlePrice {
  min-width: 200px;
  border: 2px solid #ffffff20;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.EventInformationTitlePrice b {
  font-weight: 900;
}

.EventInformationTitlePrice span {
  text-transform: capitalize;
}

.UserEventUploadAssetButton {
  background-color: white;
  color: black;
  border: 2px solid #00000070;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .EventPriceInformation {
    text-align: start;
    width: 90%;
    font-size: 12px;
    margin-left: 0%;
  }

  .UserEventUploadAssetButton,
  .EventInformationTitlePrice {
    width: 100%;
  }
}

.EventInformationDescriptionSection {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  text-align: end;
  font-size: 16px;
  gap: 20px;
}

.EventInformationDescriptionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.EventInformationDescriptionSection button {
  width: 120px;
  height: 30px;
  background-color: white;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  color: black;
}

.EventPasswordContainer {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50%;
  text-align: start;
}

.EventPasswordContainer h4 {
  color: rgb(255, 143, 143);
  font-size: 14px;
  margin: 20px 0;
}

.LockedEventEnterbutton {
  width: 150px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: black;
  display: block;
  margin: 0 auto;
}

.LockedEventInput {
  width: 40%;
  border-radius: 13px;
  border: none;
  outline: none;
  font-size: 20px;
  padding: 10px 20px;
  font-family: "Nunito";
  letter-spacing: 0.5ch;
  background-color: black;
  color: white;
  text-align: center;
}

.LockedEventIcon {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  bottom: 35px;
  left: 16.5vw;
  cursor: pointer;
  margin: 0 auto;
}

@media only screen and (max-width: 800px) {
  .EventInformationDescriptionSection {
    text-align: start;
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
  }

  .EventInformationDescriptionSection button {
    width: 100%;
  }

  .LockedEventInput {
    width: 100%;
    font-size: 16px;
  }

  .LockedEventIcon {
    bottom: 32px;
    left: 45%;
  }
}

.EventListContainer {
  width: 95%;
  min-height: 400px;
  display: flex;
  justify-content: start;
  margin-top: 50px;
  gap: 30px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 800px) {
  .EventListContainer {
    justify-content: center;
  }
}

.EventAssetDetails {
  width: 90%;
  min-height: 300px;
  margin-top: 40px;
}

.EventAssetDetailsBack {
  width: 100%;
  min-height: 300px;
}

.BackButtonContainer {
  height: 30px;
  width: 90%;
  display: flex;
  align-items: center;
}

.BackButton {
  cursor: pointer;
  padding-left: 5px;
  font-weight: bold;
}

.BackButtonIcon {
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/backArrow.png");
  filter: invert(100);
}

.MainContentContainer {
  --min-height: 700px;
  min-height: var(--min-height);
  width: 100%;
  margin-top: 40px;
  display: flex;
  /* background-color: rgb(58, 39, 78); */
}

.PosterContentContainer {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 50vw;
}

@media only screen and (max-width: 800px) {
  .MainContentContainer {
    flex-direction: column;
  }

  .PosterContentContainer {
    width: 100%;
    min-height: 500px;
    margin-bottom: 30px;
    background-color: var(--top-sheet-color);
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.205);
    padding-bottom: 30px;
  }
}

.PosterContainer {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
}

.AssetDetailsContainer {
  min-height: var(--min-height);
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: rebeccapurple; */
}

@media only screen and (max-width: 800px) {
  .AssetDetailsContainer {
    width: 100%;
  }
}

.PosterInfoDescription {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  text-align: start;
}

.PosterInfoDescription b {
  font-size: 20px;
}

@media only screen and (max-width: 800px) {
  .PosterInfoDescription {
    font-size: 13px;
  }
}

.PosterSliderActionsContainer {
  width: 100%;
  background-color: var(--top-sheet-color);
  border-radius: var(--border-radius);
  --controller-width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.PaginationButton {
  margin-top: 20px;
  width: 120px;
  height: 30px;
  background-color: white;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.EventsPopUpOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100vw;
  height: 100vh;
  background-color: #000000b3;
  z-index: 100;
  cursor: pointer;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.EventsPopUp {
  width: 600px;
  min-height: 400px;
  background-color: #000000;
  border-radius: 30px;
  border: 5px solid #ffffff59;
  padding: 30px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EventsPopUpTitle {
  font-weight: 800;
  font-style: italic;
  font-size: 35px;
}

@media only screen and (max-width: 800px) {
  .EventsPopUp {
    width: 90%;
    min-height: 60vh;
    border: 3px solid #ffffff59;
    padding: 30px 20px;
    margin-top: -10vh;
  }
}

.EventsPreview {
  width: 90%;
  height: 400px;
  background-color: rgb(76, 30, 30);
  border-radius: 20px;
  overflow: hidden;
}

.EventsPreview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 20px;
}

.EventsPopUpButtonContainer {
  margin-top: auto;
  display: flex;
  gap: 10px;
}

.EventsPopUpButton {
  border-radius: 30px;
  border: 2px solid #ffffff59;
  width: 140px;
  font-weight: bold;
}

.EventRecapDesc {
  font-size: 20px;
  padding: 20px;
}

.EventsPopUpOnCliCk {
  position: fixed;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.CreationSteps {
  width: var(--controls-width);
  background-color: var(--top-sheet-color);
  border-radius: 17px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 5px;
  font-weight: 200;
  font-style: italic;
  transition: 0.5s;
  color: #ffffff6d;
}

.CreationSteps b {
  color: white;
}

.CreationStepsBold {
  font-weight: bold;
  color: white;
}

.EventOverviewContainer {
  background: #252525;
  padding: 40px 0;
  margin-bottom: -100px;
  width: 100%;
  --sheet-layer-color: #000000a0;
  --width: 85%;
}

.EventOverviewContainer .EditActionButton {
  width: 50%;
}

.EventOverviewContainer .DeleteActionButton {
  width: 50%;
}

.EditOverview {
  width: 85%;
  margin: 20px auto;
  display: flex;
  gap: 32px;
  padding: 20px;
  border-radius: 20px;
  align-items: flex-start;
  background-color: var(--sheet-layer-color);
}

.EventOverviewAssets {
  width: 85%;
  margin: 20px auto;
  border-radius: 20px;
  padding: 20px;
  background-color: var(--sheet-layer-color);
}

.EditEventContainer {
  display: flex;
  gap: 32px;
  width: 100%;
  align-items: flex-start;
  width: 85%;
  margin: 20px auto;
}

.EditUserEventContainer {
  padding: 20px;
  background-color: var(--sheet-layer-color);
  border-radius: 20px;
  display: grid;
  gap: 20px;
  --button-color: #252525;
}

.EditEventImageParent {
  width: 40%;
}

.OverviewEventImageContainer {
  width: 100%;
  height: 525px;
  background-color: rebeccapurple;
  display: flex;
  border-radius: 19.5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.ActionButtonsContainer {
  display: grid;
  grid-template-columns: 1fr 250px;
  gap: 10px;
  justify-content: center;
  width: 85%;
  margin: 0 auto;
}

.EditActionButton,
.EditEventButton {
  width: 140px;
  height: 40px;
  display: flex;
  background-color: #ffffff26;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: none;
}

.EditEventButton {
  margin: 15px 0;
  width: 100%;
}

.DeleteActionButton {
  width: 140px;
  height: 40px;
  display: flex;
  background-color: #691822;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: none;
}

.OverviewActionButton {
  width: 160px;
  height: 40px;
  margin: 15px auto 15px 0;
  display: flex;
  background-color: #ffffff26;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: none;
}

.EditEventFieldsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ReviewRelatedImageUser {
  display: flex;
  gap: 35px;
}

.ReviewEventDetailsUser {
  background: #ffffff26;
  border-radius: 13px;
  text-align: left;
  padding: 20px;
}

.ReviewEventDetailsUser h4 {
  font-size: 28px;
  font-weight: 600;
  font-style: italic;
  margin: unset;
}

.ReviewEventStatsUser {
  background: #ffffff26;
  border-radius: 13px;
  text-align: left;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 20px;
}

.ReviewEventStatsUser > div {
  display: flex;
  align-items: baseline;
  gap: 1.2ch;
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
  margin: unset;
}

.ReviewEventStatsUser h4,
.ReviewEventStatsUser p {
  margin: unset;
  font-size: 28px;
}

.EventOverviewAssetsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: bold;
  background-color: unset;
}
.EventOverviewAssetsHeader h3 {
  margin: 0;
}

.EventUploadButton,
.ReminderEmailButton {
  width: 170px;
  height: 35px;
  display: flex;
  background-color: white;
  color: black;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
}

.ReminderEmailButton {
  height: 40px;
  font-weight: 600;
}

.ProductListContainer {
  margin-top: 40px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 0 40px;
}

.EventCountDown {
  width: 95%;
  margin-top: 10px;
}

.EventCountDownETA {
  display: flex;
  justify-content: center;
  gap: 44px;
}

.EventCountDownETA h3 {
  font-size: 44px;
  justify-content: center;
  gap: 44px;
  margin: 20px 0;
}

.AssetLike {
  width: 65px;
  height: 65px;
  margin: 0 auto;
  background-size: 44px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .EventOverviewContainer {
    padding: 10px 0;
  }

  .ReviewEventStatsUser h4,
  .ReviewEventStatsUser p {
    font-size: 18px;
  }

  .EditOverview {
    width: 100%;
    padding: 20px;
    margin: unset;
    flex-direction: column;
    align-items: stretch;
  }

  .EventOverviewAssets {
    width: 100%;
    padding: 20px;
    margin: 20px 0 0;
    flex-direction: column;
    align-items: stretch;
  }

  .ActionButtonsContainer button {
    width: 100px;
  }

  .EventOverviewAssets button {
    width: 100px;
  }

  .EditEventContainer {
    display: grid;
    gap: 32px;
    width: 85%;
  }

  .EditEventContainer > div {
    --width: 100%;
    display: grid;
  }

  .EditUserEventContainer {
    width: 100%;
  }

  .EditEventImageParent {
    width: 100%;
  }

  .OverviewEventImageContainer {
    height: 400px;
  }

  .ReviewRelatedImageUser {
    flex-wrap: wrap;
    gap: 20px;
  }

  /* .EventUploadButton {
    margin: 0 auto;
  } */

  .DeleteActionButton .EditEventButton {
    width: 100%;
  }

  .EventCountDown {
    width: 90%;
  }

  .EventCountDownETA {
    gap: 15px;
  }

  .EventCountDownETA h3 {
    font-size: 32px;
  }
}

.EventAssetEditActionButtonsContainer {
  display: flex;
  justify-content: space-between;
  width: 95%;
  gap: 10px;
  margin-right: auto;
  margin-left: auto;
  background-color: rebeccapurple;
}

.HeaderLearnMore {
  width: 200px;
  min-height: 40px;
  background-color: rgb(255, 255, 255);
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #000000;
  margin-top: 30px;
  cursor: pointer;
  font-weight: bold;
}

.EventDetailSoon {
  font-size: 40px;
  font-weight: bold;
  font-style: italic;
  margin-top: 50px;
  width: 90%;
}

.EventListDeleteItem {
  width: 100px;
  height: 30px;
  background-color: #fefefe;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #000000;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;
}

.EventListDeleteItem:hover {
  background-color: #c90000;
}

.EventDetailHeaderTitle {
  font-size: 24px !important;
  background-color: red;
}

.StickerMainExplained {
  width: 90%;
  margin-top: 50px;
}

.EventUploadVideoTitle,
.EventUploadVideoExplained {
  width: 80%;
  height: 200px;
  border-radius: 10px;
  background-color: #ffffff0f;
  margin-top: 30px;
  border: 1px solid #ffffff40;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px;
  background-image: url("../assets/UploadVideoIcon.png");
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.EventUploadVideoSubTitle {
  font-weight: 300;
  font-size: 15px;
  margin-top: 5px;
}

.EventUploadVideoTitle {
  background-image: none;
}

.UploadVideoSub {
  width: 70%;
}

.PendingOptions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.UploadVideoHow {
  margin-top: 100px;
  font-size: 20px;
  font-weight: bold;
}

.UploadVideoVideo {
  width: 70%;
  border-radius: 10px;
  margin-top: 20px;
}
