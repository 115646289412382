.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  color: #ffffff;
  justify-content: flex-start;
  text-align: flex-start;
}

.logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  background-image: url("../../../assets/logotext.png");
  height: 20px;
  width: 200px;
  align-self: flex-start;
  margin: 10px;
}

.betaLogologo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  background-image: url("../../../assets/logowhite.png");
  height: 50px;
  width: 300px;
  margin-top: 80px;
}

.subTitle {
  height: 50px;
  margin-top: 20px;
  font-weight: 900;
  font-size: 25px;
}

.newLine {
  font-size: 12spx;
  text-align: start;
}
