@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.TextLogo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logotext.png");
  height: 20px;
  width: 200px;
  margin: 10px;
}

.MainLogo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logowhite.png");
  height: 50px;
  width: 300px;
  margin-top: 80px;
}
