.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  background-color: #000000;
  min-height: 100vh;
  margin-bottom: 100px;
}

.DiscoverContentIcon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/DiscoverExample.png");
  height: 70vh;
  width: 500px;
  margin-bottom: 100px;
}

.DiscoverContent2 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/DiscoverExample2.png");
  height: 70vh;
  width: 500px;
  margin-bottom: 100px;
}

.LikeContentIcon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/DiscoverButton.png");
  height: 70px;
  width: 70px;
}

.root span {
  width: 500px;
  text-align: start;
  font-weight: 600;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .DiscoverContentIcon {
    width: 80%;
  }

  .DiscoverContent2 {
    width: 80%;
  }
  .root span {
    width: 90%;
  }
}
