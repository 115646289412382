.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  justify-self: center;
  --radius: 13px;
}

.AccountContentContainer {
  min-height: var(--min-height);
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px;
}

.CreateDiscountDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.DiscountDetailRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 600px;
}

.CreateDiscountDetails input {
  border-radius: 13px;
  background-color: #ffffff23;
  width: 50%;
  height: 36px;
  border: none;
  color: white;
  padding-left: 15px;
  font-family: "Nunito";
}

.DiscountTypesContainer {
  display: flex;
  width: 50%;
  padding: 0 5px;
}

.RadioButtonContainer {
  width: 50%;
  min-height: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.RadioButton {
  height: 16px;
  width: 16px;
  padding: 1px;
  background-color: #292a2e;
  border: solid 2px #00000099;
}

.RadioButtonPin {
  height: 100%;
  width: 100%;
  background-color: #ffffffaa;
  border: solid 1px #ffffff;
}

.ActionButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  width: 72vw;
  margin-top: 20px;
}

.ActionButtonDelete {
  background-color: #ffffff0f;
  color: #691822;
  border-radius: var(--radius);
  width: 125px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ActionButtonSave {
  background-color: #ffffff;
  color: #000000;
  border-radius: var(--radius);
  width: 125px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.CouponList {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.CouponItem,
.CouponItemInactive,
.CreateCouponButton {
  width: 100%;
  height: 40px;
  padding: 0 3%;
  background-color: #ffffff16;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  cursor: pointer;
  gap: 10px;
}

.CouponItem {
  display: grid;
  grid-template-columns: 35px 7fr repeat(2, 5fr) 75px 75px;
  text-align: left;
}

.CouponItemInactive {
  display: grid;
  grid-template-columns: 35px 110px 1fr 135px 125px 75px 75px;
  text-align: left;
}

.CouponItemInactive input {
  border: none;
  border-radius: 10px;
  height: 70%;
  background-color: rgba(255, 255, 255, 0.108);
  color: white;
  padding-left: 10px;
  margin-right: 32px;
  max-width: 300px;
}

.CouponItemInactive button,
.CouponItem button {
  border-radius: var(--radius);
}

.HeaderSearch {
  margin: auto;
  width: 90%;
  height: 35px;
  background-color: #ffffff0f;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: 2px solid white;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
}

.DiscountRowHeaderContainer {
  display: grid;
  grid-template-columns: 1fr 4fr 5fr repeat(3, 4fr) 2fr;
  margin: 20px;
  font-size: 16px;
  background: #d9d9d907;
}

.DiscountRowContainer {
  display: grid;
  grid-template-columns: 1fr 4fr 5fr repeat(3, 4fr) 2fr;
  margin: 20px;
  font-size: 16px;
  cursor: pointer;
  background: #d9d9d902;
}

.DiscountRowContainer:hover {
  background: #d9d9d907;
}

.DiscountRowContainer p,
.DiscountRowHeaderContainer p {
  text-align: left;
}

.DiscountRowContainer b,
.DiscountRowHeaderContainer b {
  text-align: right;
  margin: 1em 0;
  font-weight: 400;
}

.DiscountRowContainer i {
  text-align: left;
  margin: 1em 0;
  font-weight: 400;
}

.DiscountRowContainer img {
  width: 20px;
  padding: 1em;
  margin-left: auto;
}

.CouponItemSaveButton {
  border: none;
  cursor: pointer;
}
.CouponItemSaveButtonDisabled {
  background-color: #292a2e;
  border: none;
  cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
  .DiscountRowHeaderContainer,
  .DiscountRowContainer i {
    display: none;
  }

  .DiscountRowContainer {
    grid-template-columns: unset;
  }

  .DiscountRowContainer p,
  .DiscountRowHeaderContainer p,
  .DiscountRowContainer b,
  .DiscountRowHeaderContainer b {
    text-align: left;
    margin: 5px 0;
  }

  .DiscountRowContainer p::before,
  .DiscountRowContainer b::before {
    content: attr(data-title);
    padding-right: 1.5ch;
  }

  .CreateDiscountDetails h1 {
    font-size: 1.2em;
  }

  .DiscountDetailRow {
    width: 68vw;
    margin: 0 10px;
  }

  .DiscountTypesContainer {
    font-size: 12px;
    padding: unset;
    gap: 10px;
  }

  .RadioButtonContainer {
    gap: 2px;
  }

  .ActionButtonsContainer {
    margin-top: 20px;
    width: 68vw;
    margin: 0 10px;
  }

  .CouponItem,
  .CouponItemInactive {
    grid-template-columns: 1fr;
    height: auto;
    justify-content: flex-start;
    padding: 10px 15px;
    width: 100%;
  }

  .CouponItemInactive input {
    width: 120px;
    padding: 5px 10px;
  }

  .CouponItem button,
  .CouponItemInactive button {
    width: 100px;
    margin-left: auto;
  }
}
