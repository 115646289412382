.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  --border-radius: 15px;
  --top-sheet-color: #fdfdfd15;
  --max-width: 800px;
  padding-bottom: 200px;
  overflow: hidden;
}

@media only screen and (max-width: 800px) {
  .root {
    --max-width: 90%;
  }
}

.HeadTop {
  width: 100%;
  min-height: 200px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: red; */
}

.HeadTopMin {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}

.LogoText {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logotext.png");
  height: 40px;
  width: 150px;
}

.LogoImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logowhite.png");
  height: 60px;
  width: 60px;
}

.RecapLogoImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/RECAP_ICON.png");
  height: 150px;
  width: 250px;
  position: absolute;
  top: 15px;
}

@media only screen and (max-width: 800px) {
  .RecapLogoImage {
    width: 40%;
    top: 5px;
  }
}

.RecapDemo {
  width: var(--max-width);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  position: relative;
}

.RecapDemoImage {
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  background-image: url("../assets/RecapDemo2.png");
  /* max-height: 420px; */
  width: 500px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  aspect-ratio: 1/2;
  /* background-color: rebeccapurple; */
}

@media only screen and (max-width: 800px) {
  .RecapDemoImage {
    width: 95%;
  }
}

.RecapDemoVideo {
  height: 43%;
  width: 74.2%;
  background-color: transparent;
  margin-top: 46.7%;
}

.SubTitle {
  font-size: 40px;
  text-align: start;
  font-weight: 800;
  line-height: 40px;
}

.RecapFormFactorsTitle,
.Title {
  font-size: 70px;
  text-align: start;
  font-weight: 800;
  line-height: 70px;
}

.PackagesTitle {
  font-size: 70px;
  text-align: start;
  font-weight: 800;
  line-height: 70px;
  margin-left: 100px;
}

.SubMessage {
  font-size: 20px;
  text-align: start;
}

.Button {
  font-weight: bold;
  height: 40px;
  width: 170px;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.5s;
  color: white;
  text-decoration: none;
}

.Button:hover {
  background-color: white;
  color: black;
}

.RecapDemo .Button {
  margin-top: 100px;
}

.RecapExamples {
  margin-top: 200px;
  width: var(--max-width);
  min-height: 300px;
  /* background-color: red; */
}

.RecapFormFactor,
.PolaroidList {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.RecapFormFactor {
  margin-bottom: 100px;
  opacity: 0;
  animation: RecapFormFactorAnimation 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-play-state: paused;
}

@keyframes RecapFormFactorAnimation {
  0% {
    opacity: 0;
    margin-top: 200px;
  }
  100% {
    opacity: 1;
    margin-top: 100px;
  }
}

.PolaroidSilhouette {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/PolaroidSilhouette.png");
  height: 700px;
  width: 75%;
  animation: PolaroidSilhouetteAnimation 2s;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-play-state: paused;
  /* background-color: rebeccapurple; */
}

@keyframes PolaroidSilhouetteAnimation {
  0% {
    opacity: 0;
    margin-top: 100px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.RecapSiteNoteList {
  width: var(--max-width);
}

.RecapSiteNote {
  margin-top: 200px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  height: 400px;
}

.RecapSiteNoteHalfRight,
.RecapSiteNoteHalfLeft {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.RecapSiteNoteHalfLeft {
  width: 70%;
}

.RecapSiteNoteHalfRight {
  align-items: flex-end;
  width: 30%;
  /* background-color: rebeccapurple; */
}

@media only screen and (max-width: 800px) {
  .RecapSiteNote {
    flex-direction: column !important;
    height: 700px;
  }

  .RecapSiteNoteHalfRight {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.SaveRecap {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/SaveRecap.png");
  height: 150px;
  width: 150px;
  margin-left: auto;
}

.ARPhone {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/ARPHONE.png");
  height: 200px;
  width: 150px;
  margin-left: auto;
}

.RecapSiteNoteSwapped {
  flex-direction: row-reverse;
}

.RecapSiteNoteSwapped .SubMessage {
  text-align: right;
}

.RecapSiteNoteSwapped .Title {
  text-align: right;
}

@media only screen and (max-width: 800px) {
  .SaveRecap {
    margin-left: auto;
    margin-right: auto;
  }

  .ARPhone {
    margin-left: auto;
    margin-right: auto;
  }

  .RecapSiteNoteSwapped .SubMessage {
    text-align: left;
  }

  .RecapSiteNoteSwapped .Title {
    text-align: left;
  }
}

.RecapFormFactors {
  margin-top: 100px;
  width: var(--max-width);
}

.RecapFormFactorsTitle {
  opacity: 0;
  animation: fadeIn 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-play-state: paused;
}

.RecapFormFactorsTitleStart {
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-left: 100px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

.NextLevel {
  width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 200px;
}

.GettingStarted {
  margin-top: 200px;
  width: var(--max-width);
}

.PackagesGettingStarted {
  margin-top: 200px;
  width: 1000px;
}

.GettingStartedItem {
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  margin-top: 30px;
}

.GettingStartedStep {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  font-size: 40px;
  font-weight: bold;
  z-index: 2;
}

@media only screen and (max-width: 800px) {
  .GettingStartedStep {
    width: 50px;
    height: 50px;
    min-width: 50px;
    font-size: 30px;
  }
}

.GettingStartedMessage {
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-left: 40px;
  text-align: start;
}

.GettingStartedGuide {
  width: 3px;
  height: 100px;
  background-color: white;
  position: absolute;
  left: 35px;
  top: 35px;
  z-index: 1;
}

.Packages {
  display: flex;
  margin-top: 60px;
  gap: 20px;
}

.Package {
  border-radius: 13px;
  border: 2px solid white;
  flex: 1 1;
  /* display: grid;
  padding: 15px 30px 35px;
  grid-template-rows: 50px 75px 40px 60px 60px 45px 45px; */
  align-items: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.PackageHint {
  color: #d8d8d8d8;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-size: 13px;
}

.PackageDetail {
  color: #d8d8d8d8;
  margin: 0 0 15px;
  padding: 0 0 5px;
  border-bottom: 1px solid #ffffff30;
  font-size: 16px;
  height: 50px;
  width: 80%;
  display: flex;
  justify-content: center;

  align-items: center;
}

.PackageTitle {
  font-size: 28px;
  margin: unset;
}

.PackageTitleContainer {
  height: 70px;
}

.PackageDetail:first-of-type,
.PackageDetail:last-of-type {
  border: none;
}

@media only screen and (max-width: 800px) {
  .GettingStartedGuide {
    left: 20px;
  }

  .PackagesGettingStarted {
    max-width: 90%;
  }

  .PackagesTitle {
    margin-left: unset;
  }

  .Packages {
    display: grid;
    width: 70%;
    margin: 40px auto;
    gap: 5px;
  }
}

.GettingStartedButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
