.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  background-color: black;
  min-height: 100vh;
  margin-top: 40px;
  margin-bottom: 40px;
}

.Header {
  width: 100%;
  font-weight: 900;
  font-style: italic;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 20px;
}

.HeaderTitle {
  font-size: 60px;
  line-height: 52px;
}

.HeaderButton {
  margin-top: 30px;
  width: 200px;
  height: 30px;
  background-color: white;
  border-radius: 16px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.FrontPageCarousels {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.FrontPageCarouselsImage {
  height: 100%;
  width: 25%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media only screen and (max-width: 600px) {
  .FrontPageCarousels {
    margin-top: 50px;
    height: 500px;
    width: 90%;
    flex-direction: column;
  }

  .FrontPageCarouselsImage:nth-child(2) {
    display: none;
    background-color: red;
  }

  .FrontPageCarouselsImage:nth-child(3) {
    display: none;
    background-color: red;
  }

  .FrontPageCarouselsImage {
    width: 100%;
  }
}

.RevivarPlanner {
  height: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RevivarPlannerIcon {
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("./assets/Partnership.png");
}

@media only screen and (max-width: 600px) {
  .RevivarPlanner {
    height: 500px;
  }

  .RevivarPlannerIcon {
    width: 100px;
    height: 100px;
  }
}

.PlannerMessage {
  width: 80%;
  text-align: start;
  padding-bottom: 200px;
}

.PlannerMessageTitle {
  font-size: 60px;
  line-height: 52px;
  font-weight: 900;
  font-style: italic;
  margin-bottom: 10px;
}

.PlannerMessageSubTitle {
  font-size: 20px;
  margin-bottom: 100px;
}

.PlannerMessageImageStack {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.PlannerMessageImage {
  height: 500px;
  width: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media only screen and (max-width: 600px) {
  .PlannerMessageImage {
    height: 500px;
  }

  .PlannerMessageImage:nth-child(2) {
    display: none;
    background-color: red;
  }
}

.AboutDesignerMessage {
  height: 400px;
  width: 90%;
  padding: 20px;
  display: flex;
  gap: 30px;
  justify-content: center;
}

.AboutDesignerMessageImage {
  height: 100%;
  width: 30%;
  background-color: gainsboro;
  border-radius: 19px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/IMG_3884.png");
}

.AboutDesignerMessageProfile {
  display: flex;
  gap: 10px;
  height: 100%;
  width: 60%;
  flex-direction: column;
  align-items: start;
  text-align: start;
}

.AboutDesignerMessageProfile h1 {
  font-size: 60px;
  line-height: 52px;
  font-weight: 900;
  font-style: italic;
  padding: 0px;
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .AboutDesignerMessage {
    flex-direction: column;
    height: 900px;
  }

  .AboutDesignerMessageImage {
    width: 100%;
    height: 100%;
    background-color: red;
  }

  .AboutDesignerMessageProfile {
    width: 100%;
    align-items: center;
  }
}
