.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  /* padding: 20px; */
  /* padding-top: 10px; */
  /* gap: 20px; */
  --border-radius: 10px;
}

.LandingRoot {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  background-color: #060606;
  padding: 20px;
  padding-top: 10px;
  gap: 20px;
  --border-radius: 10px;
}

.Section {
  height: 50vh;
  width: 100%;
  background-color: #141416;
  z-index: 0;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.Section h2 {
  text-align: center;
  min-height: 70px;
  font-style: italic;
}

.SectionStacker {
  height: 50vh;
  width: 100%;
  z-index: 0;
  border-radius: var(--border-radius);
  display: flex;
  gap: 20px;
}

.SectionVideoContainer {
  width: 100%;
  height: 100%;
  background-color: #000000;
  position: relative;
}

.SectionVideoContainer video {
  width: 100%;
  height: 100%;
}

.SectionVideoContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #0000008a;
  font-weight: 600;
  display: flex;
}

.ShopButtonContainer {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: auto;
  margin-bottom: 50px;
}

.TobennaWesArtLogo {
  height: 100px;
  width: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(../assets/TobennaWesLogo.png);
}

.SectionVideoContent .TobennaWesArtLogo {
  height: 100px;
  width: 155px;
  margin-left: auto;
  margin-right: auto;
}

.ShopButton {
  height: 30px;
  width: 140px;
  color: white;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.429);
  transition: 0.5s;
  cursor: pointer;
  background: transparent;
  background-color: rgba(255, 255, 255, 0.158);
  backdrop-filter: blur(5px);
  font-weight: bold;
}

.ShopButton:hover {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.242);
}

.BackgroundImage {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.PolaroidBackgroundImage {
  background-image: url(../assets/POL2.jpg);
}

.PosterBackgroundImage {
  background-image: url(../assets/POSTER_LIST.jpeg);
}

.PostCardBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/cutePostcard.jpeg);
}

.MonaBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/mona.jpeg);
}

.WeddingBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/weddingInvite.jpeg);
}

.PlannerBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./planner/assets/IMG_38853.png);
}

.ScanRequestBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/scanRequest.jpeg);
}

.ChristmasBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/chrismasBackImage.jpg);
}

.PostcardBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/PostcardStack.jpg);
}

.BirthdayBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/GreetingCardImage.jpg);
}

.BurnaboyBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/burnaBackdrop.jpg);
}

.SaintRoomBackgroundImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/saintRoom.jpg");
}

.ProductTitle {
  margin-top: 60px;
  font-size: 45px;
  color: #141416;
  font-weight: 500;
}

.ProductSubTitle {
  font-size: 15px;
  color: #ffffff;
}
