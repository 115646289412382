.root {
  width: 100vw;
  /* min-height: 96vh; */
  /* background-color: rgb(18, 18, 18); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.polaroidRoot {
  /* background-color: rebeccapurple; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.polaroidRoot h2 {
  font-weight: 300;
}

.polaroidPreview {
  width: 95%;
  height: 400px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  gap: 10px;
}

.polaroidPreviewIdle {
  background-size: 40% 50%;
}

.polaroidPreviewImage {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.polaroidButton {
  width: 300px;
  height: 35px;
  background-color: white;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
}

.polaroidButtonMemo {
  background-color: rgba(255, 255, 255, 0.063);
  color: rgb(255, 254, 254);
}

.selectFrameButton {
  width: 300px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.canvasPreview {
  width: 300px;
  height: 55px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sliderContainer {
  width: 300px;
  background-repeat: no-repeat;
  height: 40px;
  background-position: center;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  background: #d3d3d3;
  outline: none;
  border-radius: 10px;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border: 7px solid #6c6c6c;
  background: #ffffff;
  cursor: pointer;
}

.selectImageContainer {
  height: 30px;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}

.playButton {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 25px;
  width: 25px;
  background-image: url("../assets/playButtton.png");
  margin-top: 10px;
  cursor: pointer;
}

.pausePlayButton {
  background-image: url("../assets/pauseButton.png");
}

.hidePlayButton {
  background-image: none;
  pointer-events: none;
}

.PolaroidImmersedContainer {
  height: 100%;
  width: 95%;
}

.VideoFibre {
  background-color: rgb(98, 0, 134);
  width: 360px;
  height: 360px;
}

.ImageFibre {
  background-color: rgb(98, 0, 134);
  width: 360px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.MainTitle {
  margin-top: 40px;
  font-size: 40px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SubTitle {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VariantContainer {
  margin-top: 40px;
  gap: 20px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VariantButton {
  width: 140px;
  height: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid white;
  transition: 0.5s;
  cursor: pointer;
}

.VariantButton:hover {
  background-color: white;
  color: black;
}

.VariantButtonSelected {
  background-color: white;
  color: black;
}

.NavButtonLeft {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 25px;
  width: 25px;
  background-image: url("../assets/Arrow\ Left.png");
  cursor: pointer;
  display: flex;
}
.NavButtonRight {
  background-image: url("../assets/Arrow\ Right.png");
}

.NavButtonHidden {
  opacity: 0;
}

.Title {
  margin-top: 20px;
  font-size: 25px;
  background-color: #ffffff0a;
  width: 500px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
}

@media only screen and (max-width: 600px) {
  .polaroidPreview {
    width: 70%;
    height: 300px;
  }
}

.PolaroidCloserLook {
  width: 95vw;
  height: 100%;
  margin-top: 70px;
}

.DoubleSection {
  width: 100%;
  gap: 30px;
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
}

.DoubleSectionTopic {
  width: 100%;
  height: 300px;
  background-color: rebeccapurple;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding: 20px;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}

.DoubleSectionTopic1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
    url("../assets/Pol1.png");
}

.DoubleSectionTopic2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
    url("../assets/pol2.png");
}

.DoubleSectionTopic3 {
  height: 500px;
  text-align: center;

  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
    url("../assets/Pol3.png");
}
