.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  --border-radius: 15px;
  --top-sheet-color: #fdfdfd15;
  --max-width: 800px;
  padding-bottom: 200px;
  overflow: hidden;
}

@media only screen and (max-width: 800px) {
  .root {
    --max-width: 90%;
  }
}

.HeadTop {
  width: 90%;
  min-height: 200px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  /* background-color: red; */
}

.HeadTopMin {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.LogoText {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logotext.png");
  height: 40px;
  width: 150px;
}

.LogoImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logowhite.png");
  height: 60px;
  width: 60px;
}

.SubTitle {
  font-size: 40px;
  text-align: start;
  font-weight: 800;
  line-height: 40px;
}

.Title {
  font-size: 72px;
  text-align: start;
  font-weight: 800;
  line-height: 70px;
  text-align: center;
}

.SubTitle {
  font-size: 46px;
  text-align: start;
  font-weight: 800;
  line-height: 44px;
  text-align: left;
  margin: 150px auto 0;
  width: 100%;
}

.SubMessage {
  font-size: 28px;
  text-align: center;
  font-weight: 600;
}

.SubMessageSmall {
  font-size: 20px;
  text-align: left;
  width: 100;
  margin: 10px auto;
}

.ImmersedExamples {
  width: var(--max-width);
  min-height: 300px;
  /* background-color: red; */
}

.PackageDetail {
  color: #d8d8d8d8;
  margin: 0 0 15px;
  padding: 0 0 5px;
  border-bottom: 1px solid #ffffff30;
  font-size: 16px;
  height: 50px;
  width: 80%;
  display: flex;
  justify-content: center;

  align-items: center;
}

.PackageTitle {
  font-size: 28px;
  margin: unset;
}

.PackageTitleContainer {
  height: 70px;
}

.PackageDetail:first-of-type,
.PackageDetail:last-of-type {
  border: none;
}

.headSet {
  width: 45%;
  margin-top: 100px;
}

.headSets {
  display: flex;
  justify-content: space-between;
  /* margin: 100px auto 200px; */
  width: 100%;
}

.headSetsType {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.headSetsType span {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  font-style: italic;
}

.headSetsImage {
  min-width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.headSetsButton {
  width: 250px;
  height: 40px;
  border-radius: 7px;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.immersedPhoto {
  width: 90%;
  margin: 100px 0;
  border-radius: 20px;
}

.ContactUsButton {
  width: 250px;
  height: 50px;
  border-radius: 13px;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 800px) {
  .GettingStartedGuide {
    left: 20px;
  }

  .Packages {
    display: grid;
    width: 70%;
    margin: 40px auto;
    gap: 5px;
  }

  .headSets {
    flex-direction: column;
    align-items: center;
  }
}

.GettingStartedButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
