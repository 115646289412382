.root {
  width: 100%;
  min-height: 200vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: red; */
  --controller-width: 100%;
  --sheet-color: #ffffff1b;
  --border-radius: 17px;
}

.TopSection {
  width: 100%;
  padding-top: 10vh;
  /* height: 20vh; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background-image: url("./../../assets/aleksandr-popov-hTv8aaPziOQ-unsplash.jpg"); */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
  gap: 20px;
  margin-bottom: -50px;
  display: flex;
}

.TopSectionSizeDetails {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 600px;
}

.TopSectionSizeDetailsButton {
  height: 40px;
  width: auto;
  padding: 10px;
  border: 3px solid white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

@media only screen and (max-width: 600px) {
  .TopSectionSizeDetails {
    flex-direction: column;
    margin-bottom: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
  }
}

.TopSection b {
  font-size: 80px;
  line-height: 80px;
  width: 600px;
  font-weight: 900;
}

.TopSection span {
  width: 600px;
}

@media only screen and (max-width: 600px) {
  .TopSection b {
    width: 90%;
    font-size: 11vw;
    line-height: 11vw;
  }

  .TopSection span {
    font-size: 4vw;
    line-height: 4vw;
    width: 90%;
  }
}
