.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  justify-self: center;
  --radius: 13px;
}

.AccountContentContainer {
  min-height: var(--min-height);
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px;
}

.CreateVendorDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 0 10px;
}

.CreateVendorDetails input {
  border-radius: 13px;
  background-color: #ffffff23;
  width: 50%;
  height: 36px;
  border: none;
  color: white;
  padding-left: 15px;
  font-family: "Nunito";
}

.RadioButtonContainer {
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.RadioButton {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  padding: 5px;
  background-color: rebeccapurple;
  border: solid 2px white;
}

.RadioButtonPin {
  height: 100%;
  width: 100%;
  border-radius: 100px;
  background-color: #ffffff;
}

.VendorListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: wrap;
  gap: 30px;
  padding: 10px;
}

.VendorUserContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 10px;
}

.UserListItem {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 120px;
  justify-items: flex-start;
}

.UserListItem p {
  margin: unset;
}

.UserListItem button {
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  cursor: pointer;
}

.HeaderSearch {
  margin: auto;
  width: 100%;
  height: 35px;
  background-color: #ffffff0f;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: 2px solid white;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  margin-top: 10px;
}

.ActionButton,
.DeleteActionButton {
  background-color: #ffffff;
  color: #000000;
  width: 100px;
  border-radius: 13px;
  cursor: pointer;
}

.DeleteActionButton {
  background-color: #000000;
  color: #691822;
}

.VendorEditField {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 50%;
  justify-content: space-between;
}
.VendorEditField input {
  width: 250px;
}

.VendorButtons {
  display: flex;
  gap: 10px;
  width: 100%;
}

.VendorTypeButtons {
  display: flex;
  gap: 10px;
  width: 250px;
}

.VendorTypeButtons button {
  border-radius: 13px;
  background-color: #ffffff23;
  width: 100%;
  height: 36px;
  border: none;
  color: white;
  font-family: "Nunito";
  text-align: center;
  cursor: pointer;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.VendorButtons button {
  border-radius: 13px;
  background-color: #ffffff23;
  width: 100%;
  height: 36px;
  border: none;
  color: white;
  font-family: "Nunito";
  text-align: center;
  cursor: pointer;
}

button.VendorButtonSelected {
  color: #000000;
  background: #ffffff;
}

@media only screen and (max-width: 600px) {
  .CreateVendorDetails h1 {
    font-size: 18px;
  }
  .CreateVendorDetails input {
    width: 85%;
  }

  .VendorEditField {
    width: 100%;
  }
  .UserListItem {
    grid-template-columns: 1fr 120px;
  }
  .UserListItem p:nth-of-type(2) {
    display: none;
  }
}

.vendorImage {
  height: 200px;
  width: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 7px;
  background-color: red;
}
